import jwt_decode from "jwt-decode";
import {JwtPayload} from "../helpers/UserRole";
import moment from "moment";
import "moment-timezone/index";
import {Utils} from "../helpers/Utils";

export const getToken = (): string => {
    if (localStorage.getItem('token') !== null) {
        return localStorage.getItem('token')!;
    } else {
        return '';
    }
};

export const removeToken = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('doctorid');
    localStorage.removeItem('roleEnableScan');
    localStorage.removeItem('roleEnableFileUpload');
};

export const setJwtToken = (jwtToken: any): void => {
    localStorage.setItem('token', jwtToken);
    const decoded = jwt_decode(jwtToken) as JwtPayload;
    if (decoded.userid && decoded.role=="DOCTOR")
        localStorage.setItem('doctorid', decoded.userid);
    else
        localStorage.removeItem('doctorid');
    localStorage.setItem('roleEnableScan', decoded.roleEnableScan.toString());
    localStorage.setItem('roleEnableFileUpload', decoded.roleEnableFileUpload.toString());
};

export const getDoctorId = (): string | null => {
    if (localStorage.getItem('doctorid') !== null) {
        return localStorage.getItem('doctorid')!;
    } else {
        return null;
    }
};
export const getDoctorIds = (): string[] | null => {
    const doctorIds = localStorage.getItem('doctorids');
    if (doctorIds !== null) {
        return doctorIds.split(','); // разделение строки по запятым, чтобы получить массив строк
    } else {
        return null;
    }
};
export const getSpecialGroupId = (): string[] | null => {
    const specialGroupIds = localStorage.getItem('specialgroupids');
    if (specialGroupIds !== null) {
        return specialGroupIds.split(','); // разделите строку по запятым, чтобы получить массив строк
    } else {
        return null;
    }
};

export const getRoleEnableScan = (): boolean => {
    return (localStorage.getItem('roleEnableScan') === "true");
};

export const getRoleEnableFileUpload = (): boolean => {
    return (localStorage.getItem('roleEnableFileUpload') === "true");
};

export const getAddress = (): string => {
    return window.config?.serviceUrl ?? (process.env.REACT_APP_SERVER ? `${process.env.REACT_APP_SERVER}/api` : `http://localhost:8080/api`);
};

export const getTZ = (): string => {
    return window.config?.tz ?? 'Europe/Moscow';
};

export const convertMaskedDateStringToDate = (dateString: string): Date | undefined => {
    const dateArray: string[] = dateString.split(".");
    //debugger;
    if (dateArray.length == 3) {
        const d = Date.UTC(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0]), 12, 0, 0);
        if (d && d != NaN)
            return new Date(d);
    }
    return undefined;
};

export const convertDateToMaskedDate = (date: Date): string => {
    return `${convertNumberLengthToLengthOfTwo(date.getDate())}.${convertNumberLengthToLengthOfTwo(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export const convertDateToUtcMaskedDate = (date: Date): string => {
    return `${convertNumberLengthToLengthOfTwo(date.getUTCDate())}.${convertNumberLengthToLengthOfTwo(date.getUTCMonth() + 1)}.${date.getUTCFullYear()}`;
};

export const EmptyID: string = "000000000000000000000000";

export const NullDate: Date = new Date(Date.UTC(1001, 0, 1, 12, 0, 0));

export const safeParseInt = (input: string): number => {
    const value: number = parseInt(input);

    if (isNaN(value)) {
        return 0;
    } else {
        return value < 0 ? 0 : value;
    }
};

export const convertNumberLengthToLengthOfTwo = (dateNumber: number): string => {
    if (dateNumber < 10) {
        //add "0" before 1-length digit for full calendar accepting format
        return `0${dateNumber}`;
    } else if (dateNumber > 99) {
        //remove unnecessary digits
        return `${dateNumber % 100}`;
    } else {
        return dateNumber.toString();
    }
};

export class AppMode {
    static PATIENT = 0;
    static CITIZEN = 1;
    static STUDENT = 2;
}

export enum DoctorTitleCase {
    a,
    om,
    ov,
    im,
    tab,
    u
}

export enum PatientTitleCase {
    a,
    u,
    ov,
    im,
    tab,
    mult,
    imUp,
    datm
}

export enum GeneralTitleCase {
    departmantim,
    departmanta,
    departmantov,
    departmanttab,
    specialgrouptab,
    diagnostab,
    analysistab,
    hospitalizationstab,
    appointmentstab,
    appointmentsim,
    appointmentsa,
    appointmentsov,
    showInstitutionsButtonBot
}

export enum GeneralSettingCase {
    showInstitutionsAvailable
}

export function getDoctorTitle(titleCase: DoctorTitleCase) {
    console.log(`getDoctorTitle(${titleCase}) , window.config?.DoctorTitle_a = ${window.config?.DoctorTitle_a} ${window.config?.vomode} ${window.config?.telegramAuthBotName}`);
    const title = (Utils.vomode()==AppMode.CITIZEN ? 'соц. куратор' : (Utils.vomode()==AppMode.PATIENT ? 'врач' : 'карьерный советник'))
    switch (titleCase) {
        case DoctorTitleCase.a:
            return window.config?.DoctorTitle_a ?? (Utils.vomode()!=AppMode.STUDENT ? (title + 'а') : 'карьерного советника')
        case DoctorTitleCase.om:
            return window.config?.DoctorTitle_om ?? (Utils.vomode()!=AppMode.STUDENT ? (title + 'ом') : 'карьерным советником');
        case DoctorTitleCase.ov:
            return window.config?.DoctorTitle_ov ?? (Utils.vomode()==AppMode.CITIZEN ? 'соц. кураторов' : (Utils.vomode()==AppMode.PATIENT? 'врачей' : 'карьерных советников'));
        case DoctorTitleCase.im:
            return window.config?.DoctorTitle_im ?? title;
        case DoctorTitleCase.tab:
            return window.config?.DoctorTitle_tab ?? (Utils.vomode()==AppMode.CITIZEN ? 'Соц. кураторы' : (Utils.vomode()==AppMode.PATIENT? 'Врачи' : 'Карьерные советники'));
        case DoctorTitleCase.u:
            return window.config?.DoctorTitle_u ?? (Utils.vomode()!=AppMode.STUDENT ? (title + 'у') : 'карьерному советнику');
    }
}

export function getPatientTitle(titleCase: PatientTitleCase) {
    const title = (Utils.vomode()==AppMode.CITIZEN ? 'гражданин' : (Utils.vomode()==AppMode.PATIENT? 'пациент' : 'студент'));
    switch (titleCase) {
        case PatientTitleCase.a:
            return window.config?.PatientTitle_a ?? (title + 'а');
        case PatientTitleCase.u:
            return window.config?.PatientTitle_u ?? (title + 'у');
        case PatientTitleCase.ov:
            return window.config?.PatientTitle_ov ?? (Utils.vomode()==AppMode.CITIZEN ? 'граждан' : (title+'ов'));
        case PatientTitleCase.im:
            return window.config?.PatientTitle_im ?? title;
        case PatientTitleCase.tab:
            return window.config?.PatientTitle_tab ?? (Utils.vomode()==AppMode.CITIZEN ? 'Граждане' : (Utils.vomode()==AppMode.PATIENT? 'Пациенты' : 'Студенты'));
        case PatientTitleCase.mult:
            return window.config?.PatientTitle_mult ?? (Utils.vomode()==AppMode.CITIZEN ? 'граждане' : (Utils.vomode()==AppMode.PATIENT? 'пациенты' : 'студенты'));
        case PatientTitleCase.imUp:
            return window.config?.PatientTitle_imUp ?? (Utils.vomode()==AppMode.CITIZEN ? 'Гражданин' : (Utils.vomode()==AppMode.PATIENT? 'Пациент' : 'Студент'))
        case PatientTitleCase.datm:
            return window.config?.PatientTitle_datm ?? (Utils.vomode()==AppMode.CITIZEN ? 'гражданам' : (Utils.vomode()==AppMode.PATIENT? 'пациентам' : 'студентам'));
    }
}


export function getGeneralTitle(titleCase: GeneralTitleCase) {
    switch (titleCase) {
        case GeneralTitleCase.departmantim:
            return window.config?.GeneralTitle_departmantim ?? (Utils.vomode()!=AppMode.PATIENT ? 'район' : 'отделение')
        case GeneralTitleCase.departmanta:
            return window.config?.GeneralTitle_departmanta ?? (Utils.vomode()!=AppMode.PATIENT ? 'района' : 'отделения')
        case GeneralTitleCase.departmantov:
            return window.config?.GeneralTitle_departmantov ?? (Utils.vomode()!=AppMode.PATIENT ? 'районов' : 'отделений')
        case GeneralTitleCase.departmanttab:
            return window.config?.GeneralTitle_departmanttab ?? (Utils.vomode()!=AppMode.PATIENT ? 'Районы' : 'Отделения')
        case GeneralTitleCase.specialgrouptab:
            return window.config?.GeneralTitle_specialgrouptab ?? (Utils.vomode()!=AppMode.STUDENT ? 'Особые группы' : 'Курс')
        case GeneralTitleCase.diagnostab:
            return window.config?.GeneralTitle_diagnostab ?? (Utils.vomode()!=AppMode.PATIENT ? 'Описание ситуации' : 'Диагноз')
        case GeneralTitleCase.analysistab:
            return window.config?.GeneralTitle_analysistab ?? (Utils.vomode()!=AppMode.PATIENT ? 'Документы' : 'Анализы')
        case GeneralTitleCase.hospitalizationstab:
            return window.config?.GeneralTitle_hospitalizationstab ?? (Utils.vomode()!=AppMode.PATIENT ? `Дата встречи с ${getDoctorTitle(DoctorTitleCase.om)}` : 'Госпитализация')
        case GeneralTitleCase.appointmentstab:
            return window.config?.GeneralTitle_appointmentstab ?? 'Каталог описаний заданий'
        case GeneralTitleCase.appointmentsim:
            return window.config?.GeneralTitle_appointmentsim ?? 'описание задания'
        case GeneralTitleCase.appointmentsa:
            return window.config?.GeneralTitle_appointmentsa ?? 'описания задания'
        case GeneralTitleCase.appointmentsov:
            return window.config?.GeneralTitle_appointmentsov ?? 'описаний заданий'
        case GeneralTitleCase.showInstitutionsButtonBot:
            return window.config?.GeneralTitle_showInstitutionsButtonBot ?? 'Медицинские учреждения'
    }
}

// управление настройками
export function getGeneralSetting(settingCase: GeneralSettingCase): boolean {
    switch (settingCase) {
        // Мед учреждения
        case GeneralSettingCase.showInstitutionsAvailable:
            return window.config?.GeneralSetting_showInstitutionsAvailable ? (window.config?.GeneralSetting_showInstitutionsAvailable === true) : false;

    }
}