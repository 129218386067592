import React, { Component } from 'react';
import {Dropdown, DropdownProps, Icon, Menu} from 'semantic-ui-react';
import history from "../../history";

interface State {
    limit: number;
    page: number;
    sortColumn: string;
    searchValue: string;
    loading: boolean;
}

interface TablePageProps {
    fetchData: (page?: number, sortColumn?: string, searchValue?: string, limit?: number) => Promise<void>; // Входной параметр
    totalPages: number;
    totalDocs?: number;
    prevPage?: number;
    nextPage?: number;
}

type SortOrder = 'ascending' | 'descending' | undefined;

export const setUrlParam = (key: string, value: string) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(key, value);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
}

export class TablePage extends Component<TablePageProps, State> {
    constructor(props: TablePageProps) {
        super(props);
        this.state = {
            limit: Number(this.getUrlParam("limit") || 10),
            page: Number(this.getUrlParam("page") || 1),
            sortColumn: '',
            searchValue: '',
            loading: true,
        };
    }

    handlePageLimitChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const limit = Number(data.value); // Приводим значение к числу
        this.setState({ limit, page: 1 }, () => { // Сбрасываем страницу на 1 при изменении лимита
            setUrlParam("limit", this.state.limit.toString());
            this.props.fetchData(1, this.state.sortColumn, this.state.searchValue, this.state.limit); // Передаем новый лимит
        });
    }

    handlePageChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        const page = Number(data.value); // Приводим значение к числу
        this.setState({ page }, () => { // Обновляем состояние страницы
            this.props.fetchData(page, this.state.sortColumn, this.state.searchValue, this.state.limit); // Передаем текущий лимит
        });
    }

    getUrlParam = (key: string) => {
        return new URL(window.location.href).searchParams.get(key);
    }

    getSortColumn = (): string => {
        let sort = this.getUrlParam("sort") || '';
        let order = this.getUrlParam("order") as SortOrder || "ascending";
        if (order === "descending") sort = "-" + sort;
        return sort;
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <Menu.Item
                    as='a'
                    icon
                    onClick={() => this.props.fetchData(1, this.state.sortColumn, this.state.searchValue, this.state.limit)}
                >
                    <Icon name='angle double left'/>
                </Menu.Item>

                {
                    this.props.prevPage &&
                    <Menu.Item
                        as='a'
                        onClick={() =>
                        {
                            this.props.fetchData(this.props.prevPage, this.getSortColumn(), this.state.searchValue, this.state.limit)
                        }
                        }
                    >
                        {this.props.prevPage}
                    </Menu.Item>
                }

                <Menu.Item
                    style={{background: 'lightgray'}}
                >
                    {this.state.page}
                </Menu.Item>

                {
                    this.props.nextPage &&
                    <Menu.Item
                        as='a'
                        onClick={() =>
                        {
                            this.props.fetchData(this.props.nextPage, this.getSortColumn(), this.state.searchValue, this.state.limit);
                        }
                        }
                    >
                        {this.props.nextPage}
                    </Menu.Item>
                }

                <Menu.Item
                    as='a'
                    icon
                    onClick={() => this.props.fetchData(this.props.totalPages, this.state.sortColumn, this.state.searchValue, this.state.limit)}
                >
                    <Icon name='angle double right'/>
                </Menu.Item>

                <Dropdown
                    text={`${this.state.limit} на странице`}
                    options={[
                        { key: 1, text: '10', value: 10 },
                        { key: 2, text: '25', value: 25 },
                        { key: 3, text: '50', value: 50 },
                        { key: 4, text: '100', value: 100 },
                    ]}
                    item
                    upward
                    onChange={this.handlePageLimitChange}
                />

                <Dropdown
                    text={this.state.page.toString()}
                    scrolling={true}
                    options={Array.from({ length: this.props.totalPages })
                        .map((_, i) => i + 1)
                        .map(i => ({ key: i, text: i.toString(), value: i }))}
                    item
                    upward
                    onChange={this.handlePageChange}
                />
                <Menu.Item>
                    {`Страниц: ${this.props.totalPages !== undefined ? this.props.totalPages : 0}`}
                </Menu.Item>
                <Menu.Item>
                    {`Записей: ${this.props.totalDocs}`}
                </Menu.Item>
            </div>
        );
    }
}

export default TablePage;