import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {MassiveEvent} from "../dashboard";
import {Button, Dropdown, DropdownProps, Form, Icon, Loader, Menu, Modal, Table} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import CureProgramTab from "../workTablePatients/EditorTabs/CureProgramTab";
import {
    DoctorTitleCase,
    GeneralTitleCase,
    getDoctorTitle,
    getGeneralTitle,
    getPatientTitle,
    PatientTitleCase
} from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";
import TablePage, {setUrlParam} from "../general/TablePage";
import {getEventTypesOptions} from "../../helpers/CalendarEventsRepeater";
import {getRusMassiveEventStatus} from "../../consts/EventConsts";


interface DropdownOptions {
    key: string,
    text: string,
    value: string,
}

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    massiveEvent: MassiveEvent[],
    loading: boolean,
    openRemoveModal: boolean,
    openEditMassiveEvent: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
    filterEventType?: string,
    filterMassiveEventType?: string,
    filterMassiveEventStatus?: string,
}

export interface MassiveEventPaginationResponse {
    docs: MassiveEvent[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

export class MassiveEventTypes {
    static ALL = 'all';
    static SELECTED = 'selected';
    static SPECIAL_GROUPS = 'specialgroups';
    static DOCTORS = 'doctors';
    static PARAMETERS = 'parameters'
}

export class MassiveEventStatus {
    static DONE = 'DONE';
    static PENDING = "PENDING";
    static WORK = "WORK";
    static CANCELED = "CANCELED";
}

class MassiveEventList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        massiveEvent: [],
        loading: false,
        openRemoveModal: false,
        openEditMassiveEvent: false,
        id: '',
        sortColumn: 'createDate',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getMassiveEventsList(1, 'createDate');
    };

    getMassiveEventsList = async (goToPage?: number, sortBy?: string, searchString?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
            filterEventType: this.state.filterEventType,
            filterMassiveEventType: this.state.filterMassiveEventType,
            filterMassiveEventStatus: this.state.filterMassiveEventStatus,
        };

        const response: MassiveEventPaginationResponse = await agent.PatientMassiveEventProvider.getList(paginateOptions);
        if (response) {
            setUrlParam("page", paginateOptions.page.toString());
            this.setState({
                massiveEvent: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    removeMassiveEvent = async () => {
        this.setState({loading: true});

        await agent.PatientMassiveEventProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false
        });
        window.location.reload();
        await this.getMassiveEventsList();
    };
    timeout: NodeJS.Timeout | undefined;

    MassiveEventsSearch = (): void => {
        this.setState({loading: true});
        this.getMassiveEventsList(this.state.page, '', this.state.searchValue, this.state.limit);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getMassiveEventsList(this.state.page, sortString, this.state.searchValue, this.state.limit)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    massiveEventsSearch = (): void => {
        this.setState({loading: true, page: 1});
        this.getMassiveEventsList(1, '', this.state.searchValue, this.state.limit);
    }

    static getMassiveEventTypeOptions = (): DropdownOptions[] => {
        return [
            {
                key: MassiveEventTypes.ALL,
                text: 'Все',
                value: MassiveEventTypes.ALL,
            },
            {
                key: MassiveEventTypes.SELECTED,
                text: `Выбранные ${getPatientTitle(PatientTitleCase.mult)}`,
                value: MassiveEventTypes.SELECTED,
            },
            {
                key: MassiveEventTypes.SPECIAL_GROUPS,
                text: getGeneralTitle(GeneralTitleCase.specialgrouptab),
                value: MassiveEventTypes.SPECIAL_GROUPS,
            },
            {
                key: MassiveEventTypes.DOCTORS,
                text: getDoctorTitle(DoctorTitleCase.tab),
                value: MassiveEventTypes.DOCTORS,
            },
            {
                key: MassiveEventTypes.PARAMETERS,
                text: 'По параметрам',
                value: MassiveEventTypes.PARAMETERS,
            }
        ]
    }

    static getMassiveEventStatusOptions = (): DropdownOptions[] => {
        return [{
            key: MassiveEventStatus.PENDING,
            text: 'Запланировано',
            value: MassiveEventStatus.PENDING,
        },
            {
                key: MassiveEventStatus.WORK,
                text: `В работе`,
                value: MassiveEventStatus.WORK,
            },
            {
                key: MassiveEventStatus.DONE,
                text: `Выполнено`,
                value: MassiveEventStatus.DONE,
            },
            {
                key: MassiveEventStatus.CANCELED,
                text: 'Выполнено (есть отмена)',
                value: MassiveEventStatus.CANCELED,
            }]
    }

    getTypeEventValue(chunk: string): string {
        const typeMapping: { [key: string]: string } = {
            all: "Все",
            selected: "Выбранные",
            doctors: getDoctorTitle(DoctorTitleCase.tab),
            parameters: "По параметрам",
            specialgroups: getGeneralTitle(GeneralTitleCase.specialgrouptab),
        };

        return typeMapping[chunk] || ""; // Возвращает пустую строку, если тип не найден
    }

    render() {
        // const createLabel: string = `Добавить`;
        const headerLabel: string = `Список массовых рассылок`;
        const removeLabel: string = `УДАЛИТЬ`;
        const cancelLabel: string = `Отмена`;
        const confirmRemoveLabel: string = `Вы уверены, что хотите удалить массовую рассылку?`;

        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {
                    this.state.openRemoveModal &&
                    <Modal
                        onClose={() => this.setState({openRemoveModal: false})}
                        open={this.state.openRemoveModal}
                        closeIcon
                    >
                        <Modal.Header>{confirmRemoveLabel}</Modal.Header>
                        <Modal.Actions>
                            <Button
                                content={removeLabel}
                                onClick={this.removeMassiveEvent}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openRemoveModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }

                <div className={styles.header}>
                    {headerLabel}

                    {/*<Link to={`${currentPath}/create`}>*/}
                    {/*    <Button*/}
                    {/*        className={styles.createButton}*/}
                    {/*        size={'large'}*/}
                    {/*        color={'green'}*/}
                    {/*        type={'submit'}*/}
                    {/*    >*/}
                    {/*        {createLabel}*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Field width={"3"}>
                                        <label>&nbsp;</label>
                                        <Form.Input
                                            placeholder='Поиск по описанию'
                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.searchValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({searchValue: e.target.value})
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            Статус
                                        </label>
                                        <Dropdown
                                            selection
                                            clearable={true}
                                            value={this.state.filterMassiveEventStatus}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({filterMassiveEventStatus: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.massiveEventsSearch)
                                            }}
                                            options={MassiveEventList.getMassiveEventStatusOptions()}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            Тип рассылки
                                        </label>
                                        <Dropdown
                                            selection
                                            clearable={true}
                                            value={this.state.filterMassiveEventType}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({filterMassiveEventType: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.massiveEventsSearch)
                                            }}
                                            options={MassiveEventList.getMassiveEventTypeOptions()}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            Тип заданий
                                        </label>
                                        <Dropdown
                                            selection
                                            clearable={true}
                                            value={this.state.filterEventType}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({filterEventType: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.massiveEventsSearch)
                                            }}
                                            options={getEventTypesOptions()}
                                        />
                                    </Form.Field>
                                    <Form.Button primary content='Поиск' label="&nbsp;" onClick={() => {
                                        this.MassiveEventsSearch()
                                    }}/>
                                    <Form.Button basic color='orange' content='Сбросить' label="&nbsp;"
                                                 onClick={() => {
                                                     this.setState({searchValue: "", filterMassiveEventType: "",
                                                         filterEventType: "", filterMassiveEventStatus: ""}, this.MassiveEventsSearch)
                                                 }}/>
                                </Form.Group>
                            </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Статус</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'description' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('description')}
                                            >
                                                Описание массовой рассылки
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>Тип рассылки</Table.HeaderCell>
                                            <Table.HeaderCell>Тип задания</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'startDate' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('startDate')}
                                            >Дата начала</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'endDate' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('endDate')}
                                            >Дата окончания</Table.HeaderCell>
                                            {hasRole(UserRole.ADMIN) && <Table.HeaderCell/>}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            this.state.massiveEvent && this.state.massiveEvent.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>
                                                        <Table.Cell
                                                            style={{
                                                                color: (el.status==MassiveEventStatus.PENDING)
                                                                    ? "#2185d0"
                                                                    : (!(el.status==MassiveEventStatus.DONE || el.status==MassiveEventStatus.CANCELED)
                                                                        ? "orange"
                                                                        : "#21ba45")
                                                            }}
                                                        >
                                                            {getRusMassiveEventStatus(el.status)}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            floated={'left'}
                                                            title={el.description}
                                                            >
                                                            {el.description.slice(0, 50)}
                                                        </Table.Cell>
                                                        <Table.Cell floated={'left'}>
                                                            {this.getTypeEventValue(el.type)}
                                                        </Table.Cell>
                                                        <Table.Cell floated={'left'}>
                                                            {el.eventType}
                                                        </Table.Cell>
                                                        <Table.Cell floated={'left'}>
                                                            {el.startDate && new Date(el.startDate).toLocaleDateString("RU")+' '+new Date(el.startDate).toLocaleTimeString("RU")}
                                                        </Table.Cell>
                                                        <Table.Cell floated={'left'}>
                                                            {el.endDate && new Date(el.endDate).toLocaleDateString("RU")+' '+new Date(el.endDate).toLocaleTimeString("RU")}
                                                        </Table.Cell>
                                                        {hasRole(UserRole.ADMIN) && <Table.Cell floated={'right'} style={{width: "5%"}}>
                                                            {new Date(el.startDate)>(new Date()) &&
                                                                <Button icon
                                                                onClick={() => this.setState({
                                                                openRemoveModal: true,
                                                                id: el._id
                                                                })}
                                                                >
                                                                <Icon color={"red"} name='trash alternate outline'/>
                                                                </Button>
                                                            }
                                                        </Table.Cell>}
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='7'>
                                                <Menu floated='right' pagination>

                                                    <TablePage
                                                        fetchData={this.getMassiveEventsList}
                                                        totalPages={this.state.totalPages}
                                                        totalDocs={this.state.totalDocs}
                                                        prevPage={this.state.prevPage}
                                                        nextPage={this.state.nextPage}
                                                    />

                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default MassiveEventList;