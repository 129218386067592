import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {
    Button, Table, ButtonGroup,
    Container,
    Dimmer,
    Form,
    FormButton,
    Grid,
    Header,
    InputOnChangeData,
    Loader,
    Message,
    Modal
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../agent";
import {getTZ} from "../../utils/common";
import BackupFileList from "./BackupFileList";
import ReactInputMask from "react-input-mask";
import styles from "./index.module.css";

const BackupAutoSettings = () => {
    const [settingEnabled, setSettingEnabled] = useState(false);
    const [time, setTime] = useState<string | undefined>();
    const [hour, setHour] = useState<number | undefined>();
    const [hourTimes, setHourTimes] = useState<{ hour: number; minute: number }[]>([]);
    const [minute, setMinute] = useState<number | undefined>();
    const [changed, setChanged] = useState(false);
    const [maxBackupCount, setMaxBackupCount] = useState(0);
    const [maxBackupDays, setMaxBackupDays] = useState(0);
    const [currentSettings, setCurrentSettings] = useState<any>({});
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(updateChanged, [settingEnabled, hour, minute, hourTimes, maxBackupCount, maxBackupDays, currentSettings]);

    useEffect(() => {
        query();
    }, []);

    function updateChanged() {
        setChanged(currentSettings.enabled != settingEnabled
            || currentSettings.maxBackupCount != maxBackupCount
            || currentSettings.maxBackupDays != maxBackupDays
            || currentSettings.hour != hour
            || currentSettings.minute != minute
            || currentSettings.times != hourTimes
        );
    }

    async function query() {
        let response = (await agent.ConfigProvider.get());
        setSettingEnabled(response.autoBackupSettings.enabled);
        let hour = response.autoBackupSettings.hour.toString();
        if (hour.length < 2) {
            hour = '0' + hour;
        }
        let minute = response.autoBackupSettings.minute.toString();
        if (minute.length < 2) {
            minute = '0' + minute;
        }
        setCurrentSettings(response.autoBackupSettings);
        setSettingEnabled(response.autoBackupSettings.enabled);
        setHour(hour);
        setMinute(minute);
        setMaxBackupCount(response.autoBackupSettings.maxBackupCount);
        setMaxBackupDays(response.autoBackupSettings.maxBackupDays);
        setTime(hour.toString() + ":" + minute.toString());
        setHourTimes(response.autoBackupSettings.times)
    }

    const getValueTime = (hourIn: number, minuteIn: number) => {
        let hour = hourIn.toString();
        if (hour.length < 2) {
            hour = '0' + hour;
        }
        let minute = minuteIn.toString();
        if (minute.length < 2) {
            minute = '0' + minute;
        }
        return hour.toString() + ":" + minute.toString()
    }

    const handleAdd = () => {
        // Добавляем новую строку с начальными значениями
        setHourTimes(prev => [...prev, { hour: 0, minute: 0 }]);
        // Прокрутка к новому полю ввода
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }
        }, 0);
    };

    const handleDelete = (index: number) => {
        setHourTimes(prev => prev.filter((_, i) => i !== index));
    };


    return (
        <div style={{margin: "0 0 3em"}}>

            <Header as={"h4"}>Автоматическое резервное копирование</Header>
            <Form.Checkbox label={'Выполнять ежедневное автоматическое резервное копирование'}
                           checked={settingEnabled}
                           disabled={!window.config?.backupSettingsEnabled}
                           onChange={(el, v) => {
                               console.log(v.checked!!);
                               setSettingEnabled(v.checked!!)
                           }}/>

            <div
                className={styles.backupTime}
                ref={containerRef}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }}
                }
            >
                <Table celled style={{borderTop: 'none', borderBottom: 'none'}}>
                    <Table.Header>
                        <Table.Row style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                            <Table.HeaderCell className={styles.borderTop}>
                                Время выполнения резервного копирования
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.borderTop}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {hourTimes.map((item, index) => (
                            <Table.Row>
                                <Table.Cell style={{width: '340px'}}>
                                    <input
                                        style={{width: '120px'}}
                                        type="time"
                                        disabled={!window.config?.backupSettingsEnabled}
                                        value={`${String(item.hour).padStart(2, '0')}:${String(item.minute).padStart(2, '0')}`}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const time = e.target.value;

                                            if (time === '') {
                                                setHourTimes(prev => {
                                                    const updatedTimes = [...prev];
                                                    updatedTimes[index] = { hour: 0, minute: 0 }; // Устанавливаем 0 для часов и минут
                                                    return updatedTimes;
                                                });
                                                return;
                                            }

                                            const [hourValue, minuteValue] = time.split(':').map(Number);

                                            setHourTimes(prev => {
                                                const updatedTimes = [...prev];
                                                updatedTimes[index] = { hour: hourValue, minute: minuteValue };
                                                return updatedTimes;
                                            });
                                        }}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <ButtonGroup>
                                        <Button basic
                                                onClick={() => handleDelete(index)}
                                                color='red'
                                                disabled={hourTimes.length <= 1 || !window.config?.backupSettingsEnabled}
                                        >✖</Button>
                                    </ButtonGroup>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        <Table.Row style={{ position: 'sticky', bottom: 0, background: '#f9fafb', zIndex: 10, textAlign: 'center' }}>
                            <Table.Cell className={styles.borderBottom}>
                                <Button basic
                                        onClick={handleAdd}
                                        color='green'
                                        style={{width: '100%', background: 'white'}}
                                        disabled={hourTimes.length >= 24 || !window.config?.backupSettingsEnabled}
                                >Добавить</Button>
                            </Table.Cell>
                            <Table.Cell className={styles.borderBottom}></Table.Cell>
                        </Table.Row>
                    </Table.Body>

                </Table>
            </div>

            {/* <Form.Field>
                <label>Максимальное число сохраненных резервных копий</label>
            </Form.Field>
            <Form.Input value={maxBackupCount} width={4}
                onChange={(e, v) => {
                    if (v.value)
                        setMaxBackupCount(v.value ? parseInt(v.value) : 0);
                }}
            ></Form.Input> */}
            <Form.Field>
                <label>Сколько дней хранить резервные копии</label>
            </Form.Field>
            <Form.Input value={maxBackupDays} width={4}
                        disabled={!window.config?.backupSettingsEnabled}
                        onChange={(e, v) => {
                            if (v.value)
                                setMaxBackupDays(v.value ? parseInt(v.value) : 0);
                        }}
            ></Form.Input>
            {window.config?.backupSettingsEnabled && changed &&
            <Button fluid
                    content={'Сохранить конфигурацию'}
                    color={'blue'}
                    onClick={(async (event, data) => {
                        let config = (await agent.ConfigProvider.get());
                        config.autoBackupSettings.enabled = settingEnabled;
                        config.autoBackupSettings.hour = hour;
                        config.autoBackupSettings.minute = minute;
                        config.autoBackupSettings.times = hourTimes;
                        config.autoBackupSettings.maxBackupCount = maxBackupCount;
                        config.autoBackupSettings.maxBackupDays = maxBackupDays;
                        await agent.ConfigProvider.edit(config);
                        query();
                    })}/>}

        </div>);
}

export default BackupAutoSettings;