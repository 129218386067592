import React, {ChangeEvent, FormEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import "react-datepicker/dist/react-datepicker.css";
// SivovaAlex-20240517-ActivitySettingsRollback
// import {ActivitySettings, Dept, Doctor, PatientsActivityListItem} from "../dashboard";
import {Dept, Doctor, PatientsActivityListItem} from "../dashboard";
import DatePicker from "react-datepicker";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Icon,
    Input,
    Loader,
    Menu,
    Table
} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {DoctorPaginationResponse} from "../workTableDoctors/DoctorsList";
import {
    AppMode,
    DoctorTitleCase,
    GeneralSettingCase,
    GeneralTitleCase,
    getDoctorId,
    getDoctorTitle,
    getGeneralSetting,
    getGeneralTitle,
    getPatientTitle,
    getTZ,
    PatientTitleCase
} from "../../utils/common";
import request from "superagent";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import MediaQuery from "react-responsive";
import moment from "moment";
import {firstLetterToUpperCase} from "../workTablePatients/PatientsList";
import {hasRole, UserRole} from "../../helpers/UserRole";
import {Utils} from "../../helpers/Utils";
import history from "../../history";
import { handleDatePickerKeyDown } from "../../helpers/CalendarEventsRepeater";
import TablePage, {setUrlParam} from "../general/TablePage";
import {textLabels} from "../../consts/textLabels";
// SivovaAlex-20240517-ActivitySettingsRollback
// import {ConfigPanelOptions} from "../configPanel";

// export class defaultActivityDays {
//     static WITHOUTASSIGNMENTDAYS = 2; //Без назначений
//     static INACTIVEDAYS = 10; //Неактивный
//     static PASSDAYS = 5 //Пропуск
// }

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    items: PatientsActivityListItem[],
    loading: boolean,
    openRemoveModal: boolean,
    // SivovaAlex-20240517-ActivitySettingsRollback
    // showSetting: boolean,
    // editModeSettingActivity: boolean,
    // settingActivityWithoutAssignmentDays: number,
    // settingActivityInactiveDays: number,
    // settingActivityPassDays: number,
    // settingActivityWithoutAssignmentDaysValid: boolean,
    // settingActivityInactiveDaysValid: boolean,
    // settingActivityPassDaysValid: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
    doctorid: string,
    doctors: Doctor[],
    deptId?: string,
    depts: Dept[],
    doctorDisabled: boolean,
    dateStart?: Date,
    dateEnd?: Date,
    isViewed?: boolean,
    type?: string
}

export interface PatientsActivityListItemPaginationResponse {
    docs: PatientsActivityListItem[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class PatientActivityList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        items: [],
        loading: false,
        openRemoveModal: false,
        // SivovaAlex-20240517-ActivitySettingsRollback
        // showSetting: false,
        // editModeSettingActivity: false,
        // settingActivityWithoutAssignmentDays: defaultActivityDays.WITHOUTASSIGNMENTDAYS,
        // settingActivityInactiveDays: defaultActivityDays.INACTIVEDAYS,
        // settingActivityPassDays: defaultActivityDays.PASSDAYS,
        // settingActivityWithoutAssignmentDaysValid: true,
        // settingActivityInactiveDaysValid: true,
        // settingActivityPassDaysValid: true,
        id: '',
        sortColumn: 'time',
        sortOrder: 'descending',
        searchValue: '',
        doctorid: "",
        doctors: [],
        depts: [],
        doctorDisabled: false
    };

    async componentDidMount() {
        await this.getDoctorList();
        await this.getPatientsActivityListItemsList();
        let doctorid = await getDoctorId();
        if (doctorid)
            this.setState({doctorid: doctorid, doctorDisabled: true});
        // SivovaAlex-20240517-ActivitySettingsRollback
        // await this.getSettingActivity()
    };

    getDoctorList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "surname"
        };

        const responseDoctors: DoctorPaginationResponse = await agent.DoctorProvider.getList(paginateOptions);
        const responseDepts: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (responseDoctors && responseDepts) {
            this.setState({
                doctors: responseDoctors.docs,
                depts: responseDepts.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getPatientsActivityListItemsList = async (goToPage?: number, searchString?: string,
                                              doctorid?: string, dateStart?: Date, dateEnd?: Date,
                                              isViewed?: boolean, type?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        let sort = this.state.sortColumn
        ;
        if (sort && this.state.sortOrder === "descending")
            sort = "-" + sort;

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sort,
            search: searchTo,
            doctorId: doctorid,
            dateStart: dateStart,
            dateEnd: dateEnd,
            isViewed: isViewed,
            type: type,
            deptId: this.state.deptId
        };
        if (paginateOptions.sort == null)
            paginateOptions.sort = this.state.sortColumn;

        const response: PatientsActivityListItemPaginationResponse = await agent.PatientActivityProvider.getList(paginateOptions);

        if (response) {
            setUrlParam("page", paginateOptions.page.toString());
            this.setState({
                items: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    timeout: NodeJS.Timeout | undefined;

    PatientsActivityListItemsSearch = (): void => {
        this.setState({loading: true});
        this.getPatientsActivityListItemsList(1, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type, this.state.limit);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        //let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            //  sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }
        this.setState({sortColumn: columnName});

        this.getPatientsActivityListItemsList(this.state.page, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type, this.state.limit);
    };

    handlePageLimitChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({limit: Number(data.value)}, () => {
            this.setUrlParam("limit", this.state.limit.toString());
            this.getPatientsActivityListItemsList(1, this.state.searchValue,
                this.state.doctorid, this.state.dateStart, this.state.dateEnd,
                this.state.isViewed, this.state.type, this.state.limit)
        });
    }

    setUrlParam = (key: string, value: string) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(key, value);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    // SivovaAlex-20240517-ActivitySettingsRollback
    // getSettingActivity = async () => {
    //     this.setState({ loading: true });
    //
    //     const config: ConfigPanelOptions = await agent.ConfigProvider.get();
    //
    //     if (config && config.activitySettings) {
    //         this.setState({
    //             settingActivityWithoutAssignmentDays: config.activitySettings?.withoutAssignmentDays,
    //             settingActivityInactiveDays: config.activitySettings?.inactiveDays,
    //             settingActivityPassDays: config.activitySettings?.passDays,
    //             loading: false,
    //         })
    //     } else {
    //         this.setState({ loading: false });
    //     }
    // }
    //
    // onEditSettingActivity = async () => {
    //     this.setState({ loading: true });
    //     console.log('onEditSettingActivity', this.state.settingActivityWithoutAssignmentDays,
    //         this.state.settingActivityInactiveDays,
    //         this.state.settingActivityPassDays)
    //     await agent.ConfigProvider.editActivitySettings({
    //         withoutAssignmentDays: this.state.settingActivityWithoutAssignmentDays,
    //         inactiveDays: this.state.settingActivityInactiveDays,
    //         passDays: this.state.settingActivityPassDays
    //     }).then((setting: ActivitySettings) => {
    //         this.setState({
    //             settingActivityWithoutAssignmentDays: setting.withoutAssignmentDays,
    //             settingActivityInactiveDays: setting.inactiveDays,
    //             settingActivityPassDays: setting.passDays,
    //         });
    //     });
    //
    //     this.setState({ loading: false, editModeSettingActivity: false });
    //     window.location.reload();
    // }

    options = [
        {key: false, text: 'Не просмотренные', value: false},
        {key: true, text: 'Просмотренные', value: true}
    ]

    optionsType = [
        {key: 1, text: 'Файл', value: "FILE"},
        {key: 2, text: `Связь с ${getDoctorTitle(DoctorTitleCase.om)}`, value: "EMERGENCY"},
        {key: 3, text: 'Пропуск (5 дн)', value: "DECLINED_EVENT"},
        {key: 4, text: 'Неактивный (10 дн)', value: "INACTIVE_PATIENT"},
        {key: 5, text: 'Без назначений (2 дн)', value: "EMPTY_EVENTS_PATIENT"},
        // SivovaAlex-20240517-ActivitySettingsRollback
        // {key: 3, text: 'Пропуск', value: "DECLINED_EVENT"},
        // {key: 4, text: 'Неактивный', value: "INACTIVE_PATIENT"},
        // {key: 5, text: 'Без назначений', value: "EMPTY_EVENTS_PATIENT"},
        {
            key: 6,
            text: `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`,
            value: "REMINDER"
            // value: (Utils.vomode() ? "REMINDER_VO" : "REMINDER")
        }
    ].concat(getGeneralSetting(GeneralSettingCase.showInstitutionsAvailable) ?
        [{   key: 7,
        text: `${getGeneralTitle(GeneralTitleCase.showInstitutionsButtonBot)}`,
        value: "INSTITUTION"}]
        : []
    )

    // SivovaAlex-20240517-ActivitySettingsRollback
    // isSettingSaveDisabled = (): boolean => {
    //     return !(this.state.settingActivityInactiveDaysValid
    //         && this.state.settingActivityWithoutAssignmentDaysValid
    //         && this.state.settingActivityPassDaysValid);
    // };

    render() {
        const headerLabel: string = `Активность ${getPatientTitle(PatientTitleCase.ov)}`;

        return (
            <div className={styles.table}>

                <div className={styles.header} style={{marginBottom: '24px'}}>
                    <h3>
                        {headerLabel}
                    </h3>
                    {/*<Button size='small' basic color={'teal'} onClick={() => this.setState({ showSetting: !this.state.showSetting })}>*/}
                    {/*    <Icon name='setting' size='large' style={{margin: 0}}/>*/}
                    {/*</Button>*/}
                </div>
                {/*{this.state.showSetting &&*/}
                {/*    <div className={styles.settingInput}>*/}
                {/*        <h4>Настройка периодов активности (дн.)</h4>*/}
                {/*        <Form widths={"equal"}>*/}
                {/*            <Form.Group>*/}
                {/*                <Form.Field width={"3"}>*/}
                {/*                    <Input*/}
                {/*                        label='Без назначений'*/}
                {/*                        disabled={!hasRole(UserRole.ADMIN) || !this.state.editModeSettingActivity}*/}
                {/*                        value={this.state.settingActivityWithoutAssignmentDays}*/}
                {/*                        onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                {/*                            const inputValue = e.target.value;*/}
                {/*                            const value = Number(e.target.value);*/}
                {/*                            const isNonEmpty = inputValue.trim() !== '';*/}
                {/*                            const isValid = isNonEmpty ? (value >= 1 && value <= 25) : false;*/}
                {/*                            this.setState({*/}
                {/*                                settingActivityWithoutAssignmentDays: value,*/}
                {/*                                settingActivityWithoutAssignmentDaysValid: isValid,*/}
                {/*                            });*/}
                {/*                        }}*/}
                {/*                        error={!this.state.settingActivityWithoutAssignmentDaysValid}*/}
                {/*                        content={!this.state.settingActivityWithoutAssignmentDaysValid && "Требуется значение от 1 до 25"}*/}
                {/*                    />*/}
                {/*                </Form.Field>*/}
                {/*                <Form.Field width={"3"}>*/}
                {/*                    <Input*/}
                {/*                        label='Неактивный'*/}
                {/*                        disabled={!hasRole(UserRole.ADMIN) || !this.state.editModeSettingActivity}*/}
                {/*                        value={this.state.settingActivityInactiveDays}*/}
                {/*                        onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                {/*                            const inputValue = e.target.value;*/}
                {/*                            const value = Number(e.target.value);*/}
                {/*                            const isNonEmpty = inputValue.trim() !== '';*/}
                {/*                            const isValid = isNonEmpty ? (value >= 1 && value <= 25) : false;*/}
                {/*                            this.setState({*/}
                {/*                                settingActivityInactiveDays: value,*/}
                {/*                                settingActivityInactiveDaysValid: isValid,*/}
                {/*                            });*/}
                {/*                        }}*/}
                {/*                        error={!this.state.settingActivityInactiveDaysValid}*/}
                {/*                        content={!this.state.settingActivityInactiveDaysValid && "Требуется значение от 1 до 25"}*/}
                {/*                    />*/}
                {/*                </Form.Field>*/}
                {/*                <Form.Field width={"3"}>*/}
                {/*                    <Input*/}
                {/*                        label='Пропуск'*/}
                {/*                        disabled={!hasRole(UserRole.ADMIN) || !this.state.editModeSettingActivity}*/}
                {/*                        value={this.state.settingActivityPassDays}*/}
                {/*                        onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
                {/*                            const inputValue = e.target.value;*/}
                {/*                            const value = Number(e.target.value);*/}
                {/*                            const isNonEmpty = inputValue.trim() !== '';*/}
                {/*                            const isValid = isNonEmpty ? (value >= 1 && value <= 25) : false;*/}
                {/*                            this.setState({*/}
                {/*                                settingActivityPassDays: value,*/}
                {/*                                settingActivityPassDaysValid: isValid,*/}
                {/*                            });*/}
                {/*                        }}*/}
                {/*                        error={!this.state.settingActivityPassDaysValid}*/}
                {/*                        content={!this.state.settingActivityPassDaysValid && "Требуется значение от 1 до 25"}*/}
                {/*                    />*/}
                {/*                </Form.Field>*/}
                {/*            </Form.Group>*/}
                {/*            {hasRole(UserRole.ADMIN) &&*/}
                {/*                <Form.Group style={{justifyContent: 'flex-end'}}>*/}
                {/*                {*/}
                {/*                    this.state.editModeSettingActivity ?*/}
                {/*                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>*/}
                {/*                            {this.isSettingSaveDisabled() && (*/}
                {/*                                <p style={{ color: 'red', paddingRight: '10px' }}>*/}
                {/*                                    Требуемые настройки неактивности: значения от 1 до 25*/}
                {/*                                </p>*/}
                {/*                            )}*/}
                {/*                            <Button color={'green'} type={'submit'} content={'Сохранить'}*/}
                {/*                                onClick={this.onEditSettingActivity}*/}
                {/*                                disabled={this.isSettingSaveDisabled()}*/}
                {/*                            />*/}
                {/*                        </div>*/}
                {/*                        :*/}
                {/*                        <Button color={'teal'} type={'submit'} content={'Изменить'}*/}
                {/*                            onClick={() => this.setState({ editModeSettingActivity: true, })}*/}
                {/*                        />*/}
                {/*                }*/}
                {/*                </Form.Group>*/}
                {/*            }*/}
                {/*        </Form>*/}
                {/*    </div>*/}
                {/*}*/}

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Field width={"3"}>
                                        <label>
                                            <p>ФИО {getPatientTitle(PatientTitleCase.a)}</p>
                                        </label>
                                        <Input
                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.searchValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({searchValue: e.target.value})
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            <p>{firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}</p>
                                        </label>
                                        <Dropdown
                                            disabled={this.state.doctorDisabled}
                                            selection
                                            search={true}
                                            clearable={true}
                                            value={this.state.doctorid}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({doctorid: data.value != null ? data.value.toString() : ""},
                                                    this.PatientsActivityListItemsSearch)
                                            }}
                                            options={this.state.doctors.map((doctor) => ({
                                                key: doctor._id,
                                                text: doctor.surname + " " + doctor.name[0] + "." + doctor.middlename[0] + ".",
                                                value: doctor._id,
                                            } as DropdownItemProps))}
                                            noResultsMessage={textLabels.noResultsMessage}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"3"}>
                                        <label>
                                            {firstLetterToUpperCase(getGeneralTitle(GeneralTitleCase.departmantim))}
                                        </label>
                                        <Dropdown
                                            disabled={this.state.doctorDisabled}
                                            selection
                                            search={true}
                                            clearable={true}
                                            value={this.state.deptId}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({deptId: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.PatientsActivityListItemsSearch)
                                            }}
                                            options={this.state.depts.map((dept) => ({
                                                key: dept._id,
                                                text: dept.name,
                                                value: dept._id,
                                            } as DropdownItemProps))}
                                            noResultsMessage={textLabels.noResultsMessage}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field>
                                        <label>
                                            Фильтр Просмотрено
                                        </label>
                                        <Dropdown clearable options={this.options} selection
                                                  value={this.state.isViewed}
                                                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                      let isViewed: boolean | undefined;
                                                      isViewed = data.value === true;
                                                      if (data.value === "")
                                                          isViewed = undefined;
                                                      this.setState({isViewed: isViewed},
                                                          this.PatientsActivityListItemsSearch)
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>
                                            Тип
                                        </label>
                                        <Dropdown clearable options={this.optionsType} selection
                                                  value={this.state.type}
                                                  onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                      let type: string | undefined;
                                                      if (data.value)
                                                          type = data.value.toString();
                                                      this.setState({type: type},
                                                          this.PatientsActivityListItemsSearch)
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field width={2}>
                                        <label>
                                            Дата начала
                                        </label>
                                        <DatePicker
                                            locale={"ru"}
                                            dateFormat={"dd.MM.yyyy"}
                                            todayButton={true}
                                            selected={this.state.dateStart}
                                            onChange={(v: Date) => {
                                            this.setState({
                                                dateStart: v
                                            })
                                            }}
                                            onKeyDown={handleDatePickerKeyDown}
                                        />
                                    </Form.Field>
                                    <Form.Field width={2}>
                                        <label>
                                            Дата окончания
                                        </label>
                                        <DatePicker selected={this.state.dateEnd}
                                                    locale={"ru"}
                                                    dateFormat={"dd.MM.yyyy"}
                                                    onChange={(v: Date) => {
                                                        this.setState({
                                                            dateEnd: v
                                                        })
                                                    }}
                                                    onKeyDown={handleDatePickerKeyDown}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group>
                                    <Button primary={true} content='Поиск' onClick={() => {
                                        this.PatientsActivityListItemsSearch()
                                    }}/>
                                    <Button basic color='orange' content='Сбросить'
                                            onClick={() => {
                                                this.setState({
                                                    searchValue: "",
                                                    doctorid: "",
                                                    dateStart: undefined,
                                                    dateEnd: undefined,
                                                    isViewed: undefined,
                                                    type: undefined,
                                                    sortColumn: "time"
                                                }, this.PatientsActivityListItemsSearch)
                                            }}/>
                                    <Form.Field>
                                        <label>
                                            Быстрый выбор
                                        </label>
                                        <Button.Group>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    this.setState({
                                                        dateStart: new Date(),
                                                        dateEnd: new Date()
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За сегодня</Button>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    let date: Date = new Date();
                                                    date.setDate(date.getDate() - 1);
                                                    this.setState({
                                                        dateStart: date,
                                                        dateEnd: date
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За вчера</Button>
                                            <Button
                                                tabIndex={-1}
                                                onClick={() => {
                                                    let date = this.getMonday(new Date());
                                                    this.setState({
                                                        dateStart: date,
                                                        dateEnd: new Date()
                                                    }, this.PatientsActivityListItemsSearch);
                                                }}>За эту неделю</Button>
                                        </Button.Group>
                                    </Form.Field>
                                </Form.Group>

                            </Form>

                            <div className={styles.container}>
                                <Table unstackable selectable sortable striped celled verticalAlign='middle'
                                       color={"teal"} compact={"very"}>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell>Просм.</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'time' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('time')}
                                            >Время</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'surname' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('surname')}>{firstLetterToUpperCase(getPatientTitle(PatientTitleCase.im))}</Table.HeaderCell>
                                            <MediaQuery minWidth={1224}>
                                                <Table.HeaderCell
                                                >{firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}</Table.HeaderCell>
                                                <Table.HeaderCell

                                                    sorted={this.state.sortColumn === 'type' ? this.state.sortOrder : undefined}
                                                    onClick={() => this.sortColumn('type')}
                                                >Тип</Table.HeaderCell>
                                                <Table.HeaderCell
                                                    sorted={this.state.sortColumn === 'notificationStatus' ? this.state.sortOrder : undefined}
                                                    onClick={() => this.sortColumn('notificationStatus')}>Статус<br/>
                                                    отправки<br/>email</Table.HeaderCell>
                                            </MediaQuery>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.items && this.state.items.map((el, index) =>
                                                (
                                                    <Table.Row style={{
                                                        cursor: "pointer"

                                                    }}
                                                               key={`key-${index}-${el._id}`}>
                                                        <Table.Cell textAlign={"center"} style={{
                                                            width: "10px"

                                                        }}>
                                                            <Checkbox checked={el.isViewed}
                                                                      disabled={hasRole(UserRole.OBSERVER)}
                                                                      onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                                          this.markAsViewed(el, data.checked === true)
                                                                      }}/>
                                                        </Table.Cell>

                                                        <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                            <div>
                                                                {moment(el.time).tz(getTZ()).format("DD.MM.YY")}
                                                                <br/>
                                                                {moment(el.time).tz(getTZ()).format("HH:mm:ss")}
                                                            </div>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Link
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                to={`patients/${el.patientId}/edit/patientInfo`}>
                                                                {`${el.patientName}`}
                                                            </Link>

                                                        </Table.Cell>
                                                        <MediaQuery minWidth={1224}>
                                                            <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                                <div>
                                                                    {`${el.doctorName}`}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell floated={'left'}>
                                                                <div>
                                                                    {
                                                                        (el.type === "FILE" && !hasRole(UserRole.OBSERVER)) ?
                                                                            <Link
                                                                                onClick={(e) => PatientActivityList.onFileClick(e,
                                                                                    el.fileName!, el.fileUploadDate!, el.fileContentType!)}
                                                                                to={""}>
                                                                                {PatientActivityList.getTypeDisplayName(el.type)}
                                                                            </Link>
                                                                            :
                                                                            // SivovaAlex-20240517-ActivitySettingsRollback
                                                                            // `${PatientActivityList.getTypeDisplayName(el.type, el?.activityDays)}`}
                                                                            //`${PatientActivityList.getTypeDisplayName(el.type)}${el.type=='INSTITUTION' ? ` - ${el.comment}` : ''}`}
                                                                            <>
                                                                                {PatientActivityList.getTypeDisplayName(el.type)}
                                                                                {el.type === 'INSTITUTION' && el.comment && (
                                                                                    <div>{el.comment}</div>
                                                                                )}
                                                                            </>
                                                                    }
                                                                    {(el.notificationMessage != null) ? `: ${el.notificationMessage}` : null}
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell floated={'left'} disabled={el.isViewed}>
                                                                <div>
                                                                    {`${PatientActivityList.getNotificationStatusDisplayName(el.notificationStatus)}`}
                                                                </div>
                                                            </Table.Cell>
                                                        </MediaQuery>

                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='6'>
                                                <Menu floated='right' pagination>

                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.gotoPage(1)}
                                                    >
                                                        <Icon name='angle double left'/>
                                                    </Menu.Item>

                                                    {
                                                        this.state.prevPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.gotoPage(this.state.prevPage)}
                                                            >
                                                                {this.state.prevPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        style={{background: 'lightgray'}}
                                                    >
                                                        {this.state.page}
                                                    </Menu.Item>

                                                    {
                                                        this.state.nextPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.gotoPage(this.state.nextPage)}
                                                            >
                                                                {this.state.nextPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.gotoPage(this.state.totalPages)}
                                                    >
                                                        <Icon name='angle double right'/>
                                                    </Menu.Item>

                                                    <Dropdown text={this.state.limit + ' на странице'} options={[
                                                        {key: 1, text: '10', value: 10},
                                                        {key: 2, text: '25', value: 25},
                                                        {key: 3, text: '50', value: 50},
                                                        {key: 4, text: '100', value: 100},
                                                    ]} item upward
                                                              onChange={this.handlePageLimitChange}
                                                    />

                                                    <Dropdown text={this.state.page.toString()}
                                                              scrolling={true}
                                                              options={
                                                                  Array.from<number>({length: this.state.totalPages})
                                                                      .map((_, i) => i + 1)
                                                                      .map((i) => ({key: i, text: i + "", value: i}))
                                                              } item upward
                                                              onChange={(event: SyntheticEvent<HTMLElement>, data: DropdownProps) =>
                                                                  //this.getPatientsActivityListItemsList(Number(data.value), this.state.searchValue)
                                                                  this.getPatientsActivityListItemsList(Number(data.value), this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
                                                                      this.state.isViewed, this.state.type, this.state.limit)}
                                                    />

                                                    <Menu.Item>
                                                        {`Страниц: ${this.state.totalPages !== undefined ? this.state.totalPages : 0}`}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {`Записей: ${this.state.totalDocs}`}
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }

    private static async onFileClick(e: React.MouseEvent<HTMLAnchorElement>,
                                     fileName: string,
                                     fileUploadDate: Date,
                                     fileContentType: string) {
        e.preventDefault();

        const data: request.Response | void = await agent.FileProvider.getFile(fileName!, fileUploadDate);

        if (data) {
            const newFile: Blob = new Blob([data.body], {type: fileContentType})
            const newFileURL: string = URL.createObjectURL(newFile);

            if (fileContentType.includes('image')) {
                window.open(newFileURL, '_blank');
            } else {
                let a: HTMLAnchorElement = document.createElement('a');

                a.style.display = 'none';
                document.body.appendChild(a);
                a.href = newFileURL;
                a.download = fileName;

                a.click();
            }

            URL.revokeObjectURL(newFileURL);
        }
    }
    // SivovaAlex-20240517-ActivitySettingsRollback
    // private static getTypeDisplayName(type: string, activityDays?: number | undefined) {
    private static getTypeDisplayName(type: string) {
        if (type === "EMERGENCY")
            return `Запрос связи с ${getDoctorTitle(DoctorTitleCase.om)}`;
        else if (type === "INSTITUTION")
            return `${getGeneralTitle(GeneralTitleCase.showInstitutionsButtonBot)}`;
        else if (type === "FILE")
            return "Загружен файл";
        else if (type === "DECLINED_EVENT")
            return "Пропуск (5 дн)";
            // SivovaAlex-20240517-ActivitySettingsRollback
            // return `Пропуск (${activityDays ? activityDays : defaultActivityDays.PASSDAYS} дн)`
        else if (type === "INACTIVE_PATIENT")
            return "Неактивный (10 дн)";
            // SivovaAlex-20240517-ActivitySettingsRollback
            // return `Неактивный (${activityDays ? activityDays : defaultActivityDays.INACTIVEDAYS} дн)`
        else if (type === "EMPTY_EVENTS_PATIENT")
            return "Без назначений (2 дн)";
            // SivovaAlex-20240517-ActivitySettingsRollback
            // return `Без назначений (${activityDays ? activityDays : defaultActivityDays.WITHOUTASSIGNMENTDAYS} дн)`
        else if (type === "REMINDER")
            return `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`;
        else
            return "?";
    }

    private static getNotificationStatusDisplayName(type: string) {
        if (type === "PENDING")
            return "Ожидание отправки";
        else if (type === "PENDING")
            return "Отправляется";
        else if (type === "SENT")
            return "Отправлено";
        else if (type === "ERROR")
            return "Ошибка";
        else if (type === "NONE")
            return "Нет";
        else
            return "?";
    }

    private async markAsViewed(el: PatientsActivityListItem, isViewed: boolean) {
        el.isViewed = isViewed
        await agent.PatientActivityProvider.markAsViewed(el._id, isViewed)
        this.forceUpdate();

    }

    private getMonday(d: Date): Date {
        let day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    private async gotoPage(page: number) {
        await this.getPatientsActivityListItemsList(page, this.state.searchValue, this.state.doctorid, this.state.dateStart, this.state.dateEnd,
            this.state.isViewed, this.state.type, this.state.limit);
    }
}

export default PatientActivityList;
