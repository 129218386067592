import {DaysOfWeek} from "../enums/EventEnums";
import moment from "moment/moment";
import { getTZ } from "../utils/common";

export const daysCheckboxes = [
    {
        name: DaysOfWeek.MONDAY,
        key: 'monday',
        label: 'ПН',
    },
    {
        name: DaysOfWeek.TUESDAY,
        key: 'tuesday',
        label: 'ВТ',
    },
    {
        name: DaysOfWeek.WEDNESDAY,
        key: 'wednesday',
        label: 'СР',
    },
    {
        name: DaysOfWeek.THURSDAY,
        key: 'thursday',
        label: 'ЧТ',
    },
    {
        name: DaysOfWeek.FRIDAY,
        key: 'friday',
        label: 'ПТ',
    },
    {
        name: DaysOfWeek.SATURDAY,
        key: 'saturday',
        label: 'СБ',
    },
    {
        name: DaysOfWeek.SUNDAY,
        key: 'sunday',
        label: 'ВС',
    },
];

export const buildCancellationTimeframeFrom = (date: Date | null): Date | null => {
    if (date) {
        if (!(date instanceof Date)) {
            date = new Date(date!)
        }
        date.setHours(date.getHours());
    }
    return date;
}

export const buildCancellationTimeframeTo = (date: Date | null): Date | null => {
    if (date) {
        if (!(date instanceof Date)) {
            date = new Date(date!)
        }
        date.setHours(23, 59, 59, 999);
    }
    return date;
}

export const getRusMassiveEventStatus = (status: string | undefined) : string => {
    if (status === undefined) {
        return '';
    }

    switch (status) {
        case 'DONE':
            return 'Выполнено';
        case 'CANCELED':
            return 'Выполнено (есть отмена)';
        case 'PENDING':
            return 'Запланировано';
        case 'WORK':
            return 'В работе'
        default:
            return status
    }
}