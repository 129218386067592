export const handleOnlyDigits = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const nativeEvent = e.nativeEvent as KeyboardEvent;

    // Разрешаем клавиши Backspace, Tab, Delete, стрелки и сочетания с Ctrl
    if (
        e.key === 'Backspace' ||
        e.key === 'Tab' ||
        e.key === 'Delete' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        (e.ctrlKey && (nativeEvent.code === 'KeyA' || nativeEvent.code === 'KeyC'))
    ) {
        return;
    }

    // Если нажата клавиша Ctrl + V
    if (e.ctrlKey && nativeEvent.code === 'KeyV') {
        setTimeout(() => {
            const input = e.target as HTMLInputElement;
            if (input) { // Проверяем, что input не null
                const value = input.value;

                // Фильтруем значение, чтобы оставить только цифры и точки
                const filteredValue = value.replace(/[^0-9]/g, '');
                input.value = filteredValue; // Устанавливаем отфильтрованное значение

                // Преобразуем отфильтрованное значение
                const endDate = filteredValue ? filteredValue : '';
            }
        }, 0);
        return;
    }
    // Разрешаем только цифры
    if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
    }
};

export const handleOnlyLettersAndSpecialChars = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Разрешаем только буквы, тире и пробелы
    if (!/[a-zA-Zа-яА-ЯёЁ\- ]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
    }
};