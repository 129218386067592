import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Dept, Doctor} from "../dashboard";
import {Button, Form, Icon, Loader, Menu, Modal, Table} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {Utils} from "../../helpers/Utils";
import {AppMode, DoctorTitleCase, GeneralTitleCase, getDoctorTitle, getGeneralTitle} from "../../utils/common";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import {hasRole, UserRole} from "../../helpers/UserRole";
import { firstLetterToUpperCase } from "../workTablePatients/PatientsList";
import TablePage, {setUrlParam} from "../general/TablePage";
import {TaskModel} from "../workTableTask/TaskModel";
import CreatedTaskDetailsModal from "../workTablePatients/CreatedTaskDetailsModal";

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    doctors: Doctor[],
    depts: Dept[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
    createdTask?: TaskModel,
}

export interface DoctorPaginationResponse {
    docs: Doctor[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class DoctorsList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        doctors: [],
        depts: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: '',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getDoctorsList();
        await this.getDeptList();
    };

    getDoctorsList = async (goToPage?: number, sortBy?: string, searchString?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
        };

        const response: DoctorPaginationResponse = await agent.DoctorProvider.getList(paginateOptions);

        if (response) {
            setUrlParam("page", paginateOptions.page.toString());
            this.setState({
                doctors: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    getDeptList = async (): Promise<void> => {
        this.setState({loading: true});
        const responseDepts: DeptPaginationResponse = await agent.DeptProvider.getList();

        if (responseDepts) {
            this.setState({
                depts: responseDepts.docs,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    removeDoctor = async () => {
        this.setState({loading: true});

        await agent.DoctorProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false
        });

        await this.getDoctorsList();
    };
    timeout: NodeJS.Timeout | undefined;

    doctorsSearch = (): void => {
        this.setState({loading: true});
        this.getDoctorsList(this.state.page, '', this.state.searchValue, this.state.limit);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getDoctorsList(this.state.page, sortString, this.state.searchValue, this.state.limit)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    exportDoctorList = async () => {
        let task = await agent.DoctorProvider.exportDoctorList();
        this.setState({
            createdTask: task
        });
    }

    render() {
        const createLabel: string = `Добавить ${getDoctorTitle(DoctorTitleCase.a)}`;
        const headerLabel: string = `Список ${getDoctorTitle(DoctorTitleCase.ov)}`;
        const removeLabel: string = `УДАЛИТЬ`;
        const cancelLabel: string = `Отмена`;
        const confirmRemoveLabel: string = `Вы уверены, что хотите удалить ${getDoctorTitle(DoctorTitleCase.a)}?`;

        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {
                    this.state.openRemoveModal &&
                    <Modal
                        onClose={() => this.setState({openRemoveModal: false})}
                        open={this.state.openRemoveModal}
                        closeIcon
                    >
                        <Modal.Header>{confirmRemoveLabel}</Modal.Header>
                        <Modal.Actions>
                            <Button
                                content={removeLabel}
                                onClick={this.removeDoctor}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openRemoveModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }

                <div className={styles.header}>
                    {headerLabel}
                    {hasRole(UserRole.ADMIN) &&
                        <Link to={`${currentPath}/create`}>
                            <Button
                                className={styles.createButton}
                                size={'large'}
                                color={'green'}
                                type={'submit'}
                            >
                                {createLabel}
                            </Button>
                        </Link>
                    }
                </div>
                {(hasRole(UserRole.ADMIN)) &&
                    <div style={{display: 'flex', justifyContent: 'right', marginTop: '10px'}}>
                        <Button
                                inverted
                                className={styles.createButton}
                                size={'medium'}
                                color={'green'}
                                onClick={() => this.exportDoctorList()}
                            >
                                Экспорт списка {getDoctorTitle(DoctorTitleCase.ov)}
                            </Button>
                    </div>
                }

                {this.state.createdTask && <CreatedTaskDetailsModal task={this.state.createdTask}
                                                                    onClose={() => this.setState({createdTask: undefined})}/>}

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                                <Form>
                                    <Form.Group>
                                        <Form.Input
                                            placeholder='ФИО'
                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.searchValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({searchValue: e.target.value})
                                            }}
                                        />
                                        <Form.Button primary content='Поиск' onClick={() => {
                                            this.doctorsSearch()
                                        }}/>
                                        <Form.Button basic color='orange' content='Сбросить'
                                                     onClick={() => {
                                                         this.setState({searchValue: ""}, this.doctorsSearch)
                                                     }}/>
                                    </Form.Group>
                                </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'surname' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('surname')}
                                            >ФИО</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'deptName' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('deptName')}
                                            >{firstLetterToUpperCase(getGeneralTitle(GeneralTitleCase.departmantim))}</Table.HeaderCell>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'tel' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('tel')}
                                            >Телефон</Table.HeaderCell>
                                            {hasRole(UserRole.ADMIN) && <Table.HeaderCell/>}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.doctors && this.state.doctors.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>

                                                        <Table.Cell floated={'left'}>
                                                            {hasRole(UserRole.ADMIN) ?
                                                                <Link className={styles.nameColumn}
                                                                      to={`${currentPath}/${el._id}/edit`}>
                                                                    {`${el.surname} ${el.name} ${el.middlename}`}
                                                                </Link>
                                                                :
                                                                `${el.surname} ${el.name} ${el.middlename}`
                                                            }
                                                        </Table.Cell>

                                                        <Table.Cell style={{width: "25%"}} floated={'left'}>
                                                            {hasRole(UserRole.ADMIN) ?
                                                                <Link className={styles.linkCell}
                                                                      to={`${currentPath}/${el._id}/edit`}
                                                                >
                                                                    {/*{this.state.depts.filter(dept => dept._id == el.deptId).map(dept => dept.name).join('')}*/}
                                                                    {el.deptIds ? this.state.depts.filter(dept => el.deptIds?.includes(dept._id.toString())).map(dept => dept.name).join("; ") : ''}
                                                                </Link>
                                                                :
                                                                `${el.deptIds ? this.state.depts.filter(dept => el.deptIds?.includes(dept._id.toString())).map(dept => dept.name).join("; ") : ''}`
                                                                // `${this.state.depts.filter(dept => dept._id == el.deptId).map(dept => dept.name).join('')}`
                                                            }
                                                        </Table.Cell>

                                                        <Table.Cell style={{width: "15%"}} floated={'left'}>
                                                            {hasRole(UserRole.ADMIN) ?
                                                                <Link className={styles.linkCell}
                                                                      to={`${currentPath}/${el._id}/edit`}>
                                                                    {el.tel && el.tel}
                                                                </Link>
                                                                :
                                                                `${el.tel && el.tel}`
                                                            }
                                                        </Table.Cell>

                                                        {hasRole(UserRole.ADMIN) &&
                                                            <Table.Cell floated={'right'} style={{width: "5%"}}>
                                                                <Button icon
                                                                        onClick={() => this.setState({
                                                                            openRemoveModal: true,
                                                                            id: el._id
                                                                        })}
                                                                >
                                                                    <Icon color={"red"} name='trash alternate outline'/>
                                                                </Button>
                                                            </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4'>
                                                <Menu floated='right' pagination>

                                                    <TablePage
                                                        fetchData={this.getDoctorsList}
                                                        totalPages={this.state.totalPages}
                                                        totalDocs={this.state.totalDocs}
                                                        prevPage={this.state.prevPage}
                                                        nextPage={this.state.nextPage}
                                                    />

                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default DoctorsList;
