import React, {ChangeEvent, FormEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Header,
    Icon,
    Loader,
    Menu,
    Modal,
    Table
} from 'semantic-ui-react';
import {ChangePatientDoctorsRequest, Dept, Doctor, PatientsListItem, SpecialGroup} from "../dashboard";
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import history from "../../history";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import {
    AppMode,
    DoctorTitleCase,
    GeneralTitleCase,
    getAddress,
    getDoctorId,
    getDoctorTitle,
    getGeneralTitle,
    getPatientTitle,
    getToken,
    PatientTitleCase
} from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";
import {DoctorPaginationResponse} from "../workTableDoctors/DoctorsList";
import {Utils} from "../../helpers/Utils";
import MediaQuery from "react-responsive";
import MassiveEventsModal from "./EditorTabs/MassiveEventModal";
import PatientsAnswerModal from "./EditorTabs/PatientsAnswerModal";
import ExportMassiveEventModal from "./EditorTabs/ExportMassiveEventModal";
import DatePicker from "react-datepicker";
import {TaskModel} from "../workTableTask/TaskModel";
import CreatedTaskDetailsModal from "./CreatedTaskDetailsModal";
import TablePage from "../general/TablePage";
import {getEducationFormsOptions, getFundingSourceOptions} from "../../consts/tambsuConsts";
import {handleDatePickerKeyDown} from "../../helpers/CalendarEventsRepeater";
import {handleOnlyDigits, handleOnlyLettersAndSpecialChars} from "../../consts/onKeyDownValidation";
import { textLabels } from "../../consts/textLabels";

interface Props {
    match?: { url: string };
}

type SortOrder = 'ascending' | 'descending' | undefined;

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    patients: PatientsListItem[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    searchValue: string,
    sortColumn: string,
    sortOrder: SortOrder,
    depts: Dept[],
    doctors: Doctor[],
    specialgroups: SpecialGroup[],
    deptId?: string,
    specialGroupsIdsFilter?: string[],
    activatedFilter?: boolean,
    activatedDateFromFilter?: Date,
    activatedDateToFilter?: Date,
    registerDateFromFilter?: Date,
    registerDateToFilter?: Date,
    showAllFilters: boolean,
    isExporting: boolean,
    selectedPatientIds: string[],
    selectedAll: boolean,
    openChangeDoctorModal: boolean,
    selectedChangeDoctorToId?: string,
    openCreateMassiveEventAll: boolean,
    openCreateMassiveEventSelected: boolean,
    openCreateMassiveEventParameters: boolean,
    openExportMassiveEvent: boolean,
    openCreatePatientsAnswer: boolean,
    diagnosisFilter?: string,
    telFilter?: string,
    createdTask?: TaskModel,
    typeFilter: 'all' | 'notLargeFamily' | 'isLargeFamily',
    doctorId?: string,

    // для tambsu:
    instituteFilter?: string,
    specializationFilter?: string,
    courseFilter?: string,
    educationFormFilter?: string,
    fundingSourceFilter?: string,
    structuralDivisionFilter?: string
}

interface PatientPaginationResponse {
    docs: PatientsListItem[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

export const firstLetterToUpperCase = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

class PatientsList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 0,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        patients: [],
        loading: true,
        openRemoveModal: false,
        id: '',
        searchValue: '',
        sortColumn: '',
        sortOrder: 'ascending',
        depts: [],
        doctors: [],
        specialgroups: [],
        showAllFilters: false,
        isExporting: false,
        selectedPatientIds: [],
        selectedAll: false,
        openChangeDoctorModal: false,
        openCreateMassiveEventAll: false,
        openCreateMassiveEventSelected: false,
        openCreateMassiveEventParameters: false,
        openExportMassiveEvent: false,
        openCreatePatientsAnswer: false,
        typeFilter: "all",
        doctorId: getDoctorId() ?? undefined
    };

    getUrlParam = (key: string) => {
        return new URL(window.location.href).searchParams.get(key);
    }

    setUrlParam = (key: string, value: string) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(key, value);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    async componentDidMount() {
        let page = Number(this.getUrlParam("page") || 1);
        let search = this.getUrlParam("search") || undefined;
        let sort = this.getUrlParam("sort") || undefined;
        let order = this.getUrlParam("order") as SortOrder || "ascending";
        if (order) await this.setState({sortOrder: order});
        if (order === "descending") sort = "-" + sort;

        let limit = Number(this.getUrlParam("limit") || 10);
        if (limit) await this.setState({limit: limit});

        await this.getDeptList();
        await this.getDoctorList();
        await this.getSpecialGroupList();

        await this.getPatientsList(page, sort, search).then(() => this.setState({loading: false}));
    };

    getPatientsList = async (goToPage?: number, sortBy?: string, searchString?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true, sortColumn: sortBy || ""});

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
            deptId: this.state.deptId,
            doctorId: this.state.doctorId,
            specialGroupsIdsFilter: this.state.specialGroupsIdsFilter,
            activatedFilter: this.state.activatedFilter,
            activatedDateFromFilter: this.state.activatedDateFromFilter,
            activatedDateToFilter: this.state.activatedDateToFilter,
            registerDateFromFilter: this.state.registerDateFromFilter,
            registerDateToFilter: this.state.registerDateToFilter,
            diagnosisFilter: this.state.diagnosisFilter,
            telFilter: this.state.telFilter,
            isLargeFamilyFilter: this.state.typeFilter === "isLargeFamily" ? true : undefined,
            // для tambsu:
            ...(window.config?.tambsu && {
                instituteFilter: this.state.instituteFilter,
                specializationFilter: this.state.specializationFilter,
                courseFilter: this.state.courseFilter,
                educationFormFilter: this.state.educationFormFilter,
                fundingSourceFilter: this.state.fundingSourceFilter,
                structuralDivisionFilter: this.state.structuralDivisionFilter
            })
        };

        const response: PatientPaginationResponse = await agent.PatientProvider.getList(paginateOptions);

        if (response) {

            this.setUrlParam("page", paginateOptions.page.toString());

            this.setState({
                patients: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false,
                selectedAll: false,
                searchValue: paginateOptions.search!,
                sortColumn: sortBy || ""
            });
        }
    };

    timeout: NodeJS.Timeout | undefined;

    patientsSearch = (): void => {
        this.setState({loading: true, page: 1});
        this.getPatientsList(1, '', this.state.searchValue, this.state.limit);
    }

    removePatient = async (): Promise<void> => {
        this.setState({loading: true});

        await agent.PatientProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false,
        });

        await this.getPatientsList();
    };

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getPatientsList(this.state.page, sortString, this.state.searchValue, this.state.limit)
            .then(() => {
                this.setUrlParam("sort", columnName);
                this.setUrlParam("order", this.state.sortOrder!);
                this.setState({sortColumn: columnName});
            });
    };

    getSortColumn = (): string => {
        let sort = this.getUrlParam("sort") || '';
        let order = this.getUrlParam("order") as SortOrder || "ascending";
        if (order === "descending") sort = "-" + sort;
        return sort;
    }

    activatedOptions = [
        {key: true, text: 'Да', value: true},
        {key: false, text: 'Нет', value: false}
    ]

    addPrevPathToLink = (pathname: string) => {
        return {
            pathname: pathname,
            state: {prevPath: history.location.pathname + history.location.search}
        }
    }

    getDeptList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "surname"
        };

        const responseDepts: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (responseDepts) {
            this.setState({
                depts: responseDepts.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getDoctorList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "surname"
        };

        const responseDoctors: DoctorPaginationResponse = await agent.DoctorProvider.getList(paginateOptions);

        if (responseDoctors) {
            this.setState({
                doctors: responseDoctors.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getSpecialGroupList = async (): Promise<void> => {
        this.setState({loading: true});

        const responseSpecialGroups: DoctorPaginationResponse = await agent.SpecialGroupProvider.getList();

        if (responseSpecialGroups) {
            this.setState({
                specialgroups: responseSpecialGroups.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    exportPatientList = async () => {
        let task = await agent.PatientProvider.exportPatientList(hasRole(UserRole.DOCTOR) ? this.state.doctorId : undefined);
        this.setState({
            createdTask: task
        });
    }

    exportCallHistory = async () => {
        let task = await agent.PatientProvider.exportCallHistory(hasRole(UserRole.DOCTOR) ? this.state.doctorId : undefined);
        this.setState({
            createdTask: task
        });
    }

    render() {
        const createLabel: string = `Добавить ${getPatientTitle(PatientTitleCase.a)}`;
        const getMassiveEventLabel: string = `Список массовых рассылок`;
        const headerLabel: string = `Список ${getPatientTitle(PatientTitleCase.ov)}`;
        const removeLabel: string = `УДАЛИТЬ`;
        const cancelLabel: string = `Отмена`;
        const sureLabel: string = `Вы уверены, что хотите удалить ${getPatientTitle(PatientTitleCase.a)}?`;
        const changeDoctorLabel: string = `Вы уверены, что хотите назначить отобранным ${getPatientTitle(PatientTitleCase.ov)} другого ${getDoctorTitle(DoctorTitleCase.a)}?`;

        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {
                    this.state.openRemoveModal &&
                    <Modal
                        onClose={() => this.setState({openRemoveModal: false})}
                        open={this.state.openRemoveModal}
                        closeIcon
                    >
                        <Modal.Header>{sureLabel}</Modal.Header>
                        <Modal.Actions>
                            <Button
                                content={removeLabel}
                                onClick={this.removePatient}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openRemoveModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
                &nbsp;

                {
                    this.state.openChangeDoctorModal &&
                    <Modal
                        size={"large"}
                        onClose={() => this.setState({openChangeDoctorModal: false})}
                        open={this.state.openChangeDoctorModal}
                        closeIcon
                    >
                        <Modal.Header>Перевод {getPatientTitle(PatientTitleCase.ov)}</Modal.Header>
                        <Modal.Content>
                            <p>Выполняя данную операцию,  все отмеченные {getPatientTitle(PatientTitleCase.mult)} будут
                                переведены выбранному Вами {getDoctorTitle(DoctorTitleCase.u)}.</p>
                            <p>Вами
                                отмечено {getPatientTitle(PatientTitleCase.ov)}: {this.state.selectedPatientIds.length}.</p>
                            <p>Кому перевести:</p>
                            <div style={{width: "300px"}}>
                                <Dropdown
                                    selection
                                    value={this.state.selectedChangeDoctorToId}
                                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                        this.setState({selectedChangeDoctorToId: data.value != null ? data.value.toString() : ""});
                                    }}
                                    options={this.state.doctors.map((doctor) => ({
                                        key: doctor._id,
                                        text: doctor.surname + " " + doctor.name + " " + doctor.middlename,
                                        value: doctor._id,
                                    } as DropdownItemProps))}
                                />
                            </div>

                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                disabled={!this.state.selectedChangeDoctorToId}
                                content={"Перевести"}
                                onClick={async () => {
                                    var request: ChangePatientDoctorsRequest = {
                                        doctorId: this.state.selectedChangeDoctorToId!,
                                        patientIds: this.state.selectedPatientIds
                                    };
                                    await agent.PatientProvider.changePatientsDoctor(request);
                                    this.setState({openChangeDoctorModal: false});
                                    window.location.reload();
                                }}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openChangeDoctorModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.createdTask && <CreatedTaskDetailsModal task={this.state.createdTask}
                                                                    onClose={() => this.setState({createdTask: undefined})}/>}

                <div className={styles.header}>
                    <h3>{headerLabel}</h3>

                    {!hasRole(UserRole.OBSERVER) &&
                    <Link to={`${currentPath}/create`}>
                        <Button
                            className={styles.createButton}
                            size={'large'}
                            color={'green'}
                            type={'submit'}
                        >
                            {createLabel}
                        </Button>
                    </Link>}
                </div>
                {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) &&
                <div style={{display: 'flex', justifyContent: 'right'}}>
                    <Link to={`patientMassiveEvent/getList`}>
                        <Button
                            inverted
                            className={styles.createButton}
                            size={'medium'}
                            color={'green'}
                            type={'submit'}
                        >
                            {getMassiveEventLabel}
                        </Button>
                    </Link>
                </div>
                }
                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        label={"ФИО"}
                                        width={"4"}
                                        iconPosition='left'
                                        icon={<Icon name='search'/>}
                                        value={this.state.searchValue}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            this.setState({searchValue: e.target.value})
                                        }}
                                        onKeyDown={handleOnlyLettersAndSpecialChars}
                                    />
                                    <Form.Field>
                                        <label>
                                            {getGeneralTitle(GeneralTitleCase.diagnostab)}
                                        </label>
                                        <Form.Input
                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.diagnosisFilter}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({diagnosisFilter: e.target.value})
                                            }}
                                        />
                                    </Form.Field>
                                    {!hasRole(UserRole.DOCTOR) &&
                                        <Form.Field width={"3"}>
                                            <label>
                                                {firstLetterToUpperCase(getGeneralTitle(GeneralTitleCase.departmantim))}
                                            </label>
                                            <Dropdown
                                                selection
                                                search={true}
                                                clearable={true}
                                                value={this.state.deptId}
                                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                    this.setState({deptId: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                        this.patientsSearch)
                                                }}
                                                options={this.state.depts.map((dept) => ({
                                                    key: dept._id,
                                                    text: dept.name,
                                                    value: dept._id,
                                                } as DropdownItemProps))}
                                                noResultsMessage={textLabels.noResultsMessage}
                                            />
                                        </Form.Field>
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field width={"4"}>
                                        <label>
                                            {firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}
                                        </label>
                                        <Dropdown
                                            selection
                                            search={true}
                                            disabled={!!getDoctorId()}
                                            clearable={true}
                                            value={this.state.doctorId}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({doctorId: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.patientsSearch)
                                            }}
                                            options={this.state.doctors.map((doctor) => ({
                                                key: doctor._id,
                                                text: doctor.surname + " " + doctor.name + " " + doctor.middlename,
                                                value: doctor._id,
                                            } as DropdownItemProps))}
                                            noResultsMessage={textLabels.noResultsMessage}
                                        />
                                    </Form.Field>
                                    <Form.Field width={"4"}>
                                        <label>
                                            {getGeneralTitle(GeneralTitleCase.specialgrouptab)}
                                        </label>
                                        <Dropdown
                                            selection
                                            multiple
                                            search={true}
                                            clearable={true}
                                            value={this.state.specialGroupsIdsFilter}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                const selectedValues = data.value as string[];

                                                this.setState({
                                                    specialGroupsIdsFilter: selectedValues.length > 0 ? selectedValues : undefined
                                                }, this.patientsSearch);
                                            }}
                                            options={this.state.specialgroups.map((specialgroup) => ({
                                                key: specialgroup._id,
                                                text: specialgroup.name,
                                                value: specialgroup._id,
                                            } as DropdownItemProps))}
                                            noResultsMessage={textLabels.noResultsMessage}
                                        />
                                    </Form.Field>
                                    <Form.Button basic
                                                 type={'button'}
                                                 content={
                                                     <>
                                                         Все фильтры {this.state.showAllFilters ?
                                                         <Icon name='chevron up'/> : <Icon name='chevron down'/>}
                                                     </>
                                                 }
                                                 label="&nbsp;"
                                                 color={this.state.showAllFilters ? 'grey' : 'blue'}
                                                 onClick={() => {
                                                     this.setState({
                                                         showAllFilters: !this.state.showAllFilters
                                                     })
                                                 }}

                                    />
                                    <Form.Button primary content='Поиск'
                                                 label="&nbsp;"
                                                 onClick={() => {
                                                     this.setUrlParam("search", this.state.searchValue);
                                                     this.setUrlParam("page", "1");
                                                     this.patientsSearch();
                                                 }}/>
                                    <div style={{paddingTop: "24px"}}>

                                        <Menu>
                                            <Menu.Item color='orange' content='Сбросить'
                                                       onClick={() => {
                                                           this.setUrlParam("search", "");
                                                           this.setUrlParam("page", "1");
                                                           this.setState({
                                                               searchValue: "",
                                                               doctorId: hasRole(UserRole.DOCTOR) ? this.state.doctorId : undefined,
                                                               deptId: undefined,
                                                               diagnosisFilter: undefined,
                                                               telFilter: undefined,
                                                               specialGroupsIdsFilter: undefined,
                                                               activatedFilter: undefined,
                                                               activatedDateFromFilter: undefined,
                                                               activatedDateToFilter: undefined,
                                                               registerDateFromFilter: undefined,
                                                               registerDateToFilter: undefined,
                                                               // для tambsu:
                                                               ...(window.config?.tambsu && {
                                                                   instituteFilter: undefined,
                                                                   specializationFilter: undefined,
                                                                   courseFilter: undefined,
                                                                   educationFormFilter: undefined,
                                                                   fundingSourceFilter: undefined,
                                                                   structuralDivisionFilter: undefined
                                                               })
                                                           }, this.patientsSearch)
                                                       }}/>

                                            {!hasRole(UserRole.OBSERVER) &&
                                                <Dropdown text='Выполнить' pointing className='link item'
                                                      disabled={this.state.isExporting || this.state.openCreateMassiveEventAll || this.state.openCreateMassiveEventSelected || this.state.openCreateMassiveEventParameters || this.state.openCreatePatientsAnswer || this.state.openExportMassiveEvent}>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={() => this.exportPatientList()}>Экспорт
                                                        списка {getPatientTitle(PatientTitleCase.ov)}</Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => this.exportCallHistory()}>Экспорт истории
                                                        обращений {getPatientTitle(PatientTitleCase.ov)}</Dropdown.Item>
                                                    {hasRole(UserRole.ADMIN) && <>
                                                        <Dropdown.Item
                                                            onClick=
                                                                {() => {
                                                                    this.setState({
                                                                        openExportMassiveEvent: true
                                                                    });
                                                                }}
                                                        >Экспорт массовых рассылок</Dropdown.Item>

                                                        {/*<Dropdown.Item*/}
                                                        {/*onClick={() => {*/}
                                                        {/*    let url = `${getAddress()}/patient/exportMassiveEvent`;*/}
                                                        {/*    this.viewFile(url, "export_people_massive_event_");*/}
                                                        {/*    //window.open(, "_self");*/}
                                                        {/*}}>Экспорт массовых рассылок</Dropdown.Item>*/}
                                                        {this.state.selectedPatientIds.length > 0 &&
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    openChangeDoctorModal: true,
                                                                    selectedChangeDoctorToId: undefined
                                                                });
                                                                // console.log(this.state.selectedChangeDoctorToId)
                                                            }}>Перевести
                                                            выбранных {getPatientTitle(PatientTitleCase.ov)} другому {getDoctorTitle(DoctorTitleCase.u)}</Dropdown.Item>}
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    openCreateMassiveEventAll: true
                                                                });
                                                                // console.log("openCreateMassiveEventAll", this.state.openCreateMassiveEventAll)
                                                            }}
                                                        >
                                                            Создать массовую рассылку для всех
                                                        </Dropdown.Item>
                                                        {this.state.selectedPatientIds.length > 0 &&
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                this.setState({
                                                                    openCreateMassiveEventSelected: true
                                                                });
                                                                //console.log("openCreateMassiveEventSelected", this.state.openCreateMassiveEventSelected)
                                                            }}
                                                        >
                                                            Создать массовую рассылку для<br/>выбранных
                                                        </Dropdown.Item>
                                                    }
                                                    {(this.state.doctors.length > 0 || this.state.specialgroups.length > 0) &&
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    this.setState({
                                                                        openCreateMassiveEventParameters: true
                                                                    });
                                                                }}
                                                            >
                                                                Создать массовую рассылку<br />по параметрам
                                                    </Dropdown.Item>}
                                                    {window.config?.useExportPatientAnswer === true &&
                                                        <Dropdown.Item
                                                            onClick=
                                                                {() => {
                                                                    this.setState({
                                                                        openCreatePatientsAnswer: true
                                                                    });
                                                                }}
                                                        >Экспорт
                                                            ответов {getPatientTitle(PatientTitleCase.ov)}</Dropdown.Item>}

                                                    </>}
                                                    {this.state.openCreateMassiveEventSelected &&
                                                    <MassiveEventsModal
                                                        type="selected"
                                                        patientIds={this.state.selectedPatientIds}/>
                                                    }
                                                    {this.state.openCreateMassiveEventAll &&
                                                        <MassiveEventsModal type="all" patientIds={[]}/>}
                                                    {this.state.openCreateMassiveEventParameters &&
                                                        <MassiveEventsModal type="parameters" doctor={this.state.doctorId} specialgroups={this.state.specialGroupsIdsFilter} patientIds={[]}/>}
                                                    {this.state.openCreatePatientsAnswer &&
                                                    <PatientsAnswerModal
                                                        onClose={(task) => this.setState({
                                                            openCreatePatientsAnswer: false,
                                                            createdTask: task
                                                        })}/>}
                                                    {this.state.openExportMassiveEvent &&
                                                    <ExportMassiveEventModal
                                                        onClose={(task) => this.setState({
                                                            openExportMassiveEvent: false,
                                                            createdTask: task
                                                        })}/>}
                                                </Dropdown.Menu>
                                            </Dropdown>}
                                        </Menu>
                                    </div>
                                </Form.Group>

                                {this.state.showAllFilters &&
                                    <>
                                        <Form.Group>
                                            <Form.Field width={"3"}>
                                                <label>Активирован</label>
                                                <Dropdown
                                                    clearable
                                                    options={this.activatedOptions}
                                                    selection
                                                    value={this.state.activatedFilter}
                                                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                        let isActivated: boolean | undefined;
                                                        isActivated = data.value === true;
                                                        if (data.value === "")
                                                            isActivated = undefined;
                                                        this.setState({activatedFilter: isActivated}, this.patientsSearch)}}
                                                />
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <label>
                                                    Дата регистрации с
                                                </label>
                                                <DatePicker
                                                    locale={"ru"}
                                                    dateFormat={"dd.MM.yyyy"}
                                                    todayButton={true}
                                                    selected={this.state.registerDateFromFilter}
                                                    onChange={(v: Date) => {
                                                    this.setState({
                                                        registerDateFromFilter: v
                                                    })
                                                    }}
                                                    onKeyDown={handleDatePickerKeyDown}
                                                />
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <label>
                                                    Дата регистрации до
                                                </label>
                                                <DatePicker selected={this.state.registerDateToFilter}
                                                            locale={"ru"}
                                                            dateFormat={"dd.MM.yyyy"}
                                                            onChange={(v: Date) => {
                                                                this.setState({
                                                                    registerDateToFilter: v
                                                                })
                                                            }}
                                                            onKeyDown={handleDatePickerKeyDown}
                                                            minDate={this.state.registerDateFromFilter}
                                                            maxDate={new Date()}
                                                />
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <label>
                                                    Дата активации с
                                                </label>
                                                <DatePicker
                                                    locale={"ru"}
                                                    dateFormat={"dd.MM.yyyy"}
                                                    todayButton={true}
                                                    selected={this.state.activatedDateFromFilter}
                                                    onChange={(v: Date) => {
                                                    this.setState({
                                                        activatedDateFromFilter: v
                                                    })
                                                    }}
                                                    onKeyDown={handleDatePickerKeyDown}
                                                />
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <label>
                                                    Дата активации до
                                                </label>
                                                <DatePicker selected={this.state.activatedDateToFilter}
                                                            locale={"ru"}
                                                            dateFormat={"dd.MM.yyyy"}
                                                            onChange={(v: Date) => {
                                                                this.setState({
                                                                    activatedDateToFilter: v
                                                                })
                                                            }}
                                                            onKeyDown={handleDatePickerKeyDown}
                                                            minDate={this.state.activatedDateFromFilter}
                                                            maxDate={new Date()}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Field width={"3"}>
                                                <label>Телефон</label>
                                                <Form.Input
                                                iconPosition='left'
                                                icon={<Icon name='search'/>}
                                                value={this.state.telFilter}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    const inputValue = e.target.value;
                                                    const isNonEmpty = inputValue.trim() !== '';
                                                    const filteredValue = isNonEmpty ? inputValue.replace(/[^0-9]/g, '') : '';
                                                    this.setState({telFilter: filteredValue})
                                                }}
                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    handleOnlyDigits(e);
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        this.patientsSearch();
                                                    }
                                                }}
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        {window.config?.tambsu &&
                                            <Form.Group widths={"equal"}>
                                                <Form.Input
                                                    label={"Институт"}
                                                    iconPosition='left'
                                                    icon={<Icon name='search'/>}
                                                    value={this.state.instituteFilter}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({instituteFilter: e.target.value})
                                                    }}
                                                />
                                                <Form.Input
                                                    label={"Специальность"}
                                                    iconPosition='left'
                                                    icon={<Icon name='search'/>}
                                                    value={this.state.specializationFilter}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({specializationFilter: e.target.value})
                                                    }}
                                                />
                                                <Form.Input
                                                    label={"Курс"}
                                                    iconPosition='left'
                                                    icon={<Icon name='search'/>}
                                                    value={this.state.courseFilter}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({courseFilter: e.target.value})
                                                    }}
                                                />
                                                <Form.Field>
                                                    <label>Форма обучения</label>
                                                    <Dropdown
                                                        selection
                                                        search={true}
                                                        clearable={true}
                                                        value={this.state.educationFormFilter}
                                                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                            this.setState({educationFormFilter: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                                this.patientsSearch)
                                                        }}
                                                        options={getEducationFormsOptions()}
                                                        noResultsMessage={textLabels.noResultsMessage}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Источник финансирования</label>
                                                    <Dropdown
                                                        selection
                                                        search={true}
                                                        clearable={true}
                                                        value={this.state.fundingSourceFilter}
                                                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                            this.setState({fundingSourceFilter: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                                this.patientsSearch)
                                                        }}
                                                        options={getFundingSourceOptions()}
                                                        noResultsMessage={textLabels.noResultsMessage}
                                                    />
                                                </Form.Field>
                                                <Form.Input
                                                    label={"Структурное подразделение"}
                                                    iconPosition='left'
                                                    icon={<Icon name='search'/>}
                                                    value={this.state.structuralDivisionFilter}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({structuralDivisionFilter: e.target.value})
                                                    }}
                                                />
                                        </Form.Group>
                                        }
                                </>}
                            </Form>


                            <Menu tabular>
                                <Menu.Item
                                    className={styles.item}
                                    content='Все'
                                    active={this.state.typeFilter === 'all'}
                                    onClick={() => {
                                        this.setState({'typeFilter': 'all'}, this.patientsSearch);
                                    }}
                                />
                                <Menu.Item
                                    content='Многодетные семьи'
                                    active={this.state.typeFilter === 'isLargeFamily'}
                                    onClick={() => {
                                        this.setState({'typeFilter': 'isLargeFamily'}, this.patientsSearch);
                                    }}
                                />
                            </Menu>

                            {/*<div className={styles.container}>*/}

                            <Table stackable selectable compact sortable striped verticalAlign='middle'
                                   color={"blue"}>
                                <Table.Header className={styles.tableHeader}>
                                    <Table.Row>
                                        {hasRole(UserRole.ADMIN) &&
                                        <Table.HeaderCell textAlign={"center"}>
                                            <Checkbox
                                                checked={this.state.selectedAll}
                                                onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                    const selectedAll = data.checked === true;
                                                    var newArray = [...this.state.selectedPatientIds];
                                                    this.state.patients.forEach(function (patient) {
                                                        var index = (newArray.indexOf(patient._id));
                                                        var exists = index >= 0;
                                                        if (!exists && selectedAll)
                                                            newArray.push(patient._id);
                                                        else if (exists && !selectedAll)
                                                            newArray.splice(index, 1);
                                                    });
                                                    this.setState({
                                                        selectedAll: selectedAll,
                                                        selectedPatientIds: newArray
                                                    })

                                                }}/>

                                        </Table.HeaderCell>}
                                        <Table.HeaderCell
                                            sorted={this.state.sortColumn.includes('surname') ? this.state.sortOrder : undefined}
                                            onClick={() => this.sortColumn('surname')}
                                        >
                                            <Header as='h5' textAlign='left'>
                                                ФИО
                                            </Header>
                                        </Table.HeaderCell>

                                        <MediaQuery minWidth={1224}>
                                            <Table.HeaderCell width={"2"}>
                                                <Header as='h5' textAlign='center'>
                                                    {Utils.vomode() != AppMode.STUDENT ? firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im)) : <>Карьерный<br/>советник</>}
                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell>
                                                <Header as='h5' textAlign='center'>
                                                    Информация<br/>для Вас
                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell>
                                                <Header as='h5' textAlign='center'>
                                                    {Utils.vomode() != AppMode.PATIENT ?
                                                        <p>Дата встречи с<br/>{getDoctorTitle(DoctorTitleCase.om)}
                                                        </p> :
                                                        <p>Госпитализация</p>}

                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell

                                                sorted={this.state.sortColumn.includes('diagnosis') ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('diagnosis')}>
                                                <Header as='h5' textAlign='left'>
                                                    {Utils.vomode() != AppMode.PATIENT ?
                                                        <p>Описание<br/>ситуации</p> : "Диагноз"}
                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell>
                                                <Header as='h5' textAlign='center'>
                                                    {Utils.vomode() != AppMode.STUDENT ? <>Особые<br/>группы</> : 'Курс'}
                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn.includes('activated') ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('activated')}
                                            >
                                                <Header as='h5' textAlign='center'>
                                                    Активирован
                                                </Header>
                                            </Table.HeaderCell>

                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn.includes('dateofbirth') ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('dateofbirth')}
                                            >
                                                <Header as='h5' textAlign='center'>
                                                    Дата<br/>рождения
                                                </Header>
                                            </Table.HeaderCell>


                                        </MediaQuery>
                                        <Table.HeaderCell
                                            //sorted={this.state.sortColumn.includes('tel') ? this.state.sortOrder : undefined}
                                            //onClick={() => this.sortColumn('tel')}
                                        >
                                            <Header as='h5' textAlign='center'>
                                                Телефон
                                            </Header>
                                        </Table.HeaderCell>

                                        {hasRole(UserRole.ADMIN) &&
                                        <Table.HeaderCell/>}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        this.state.patients && this.state.patients.map((el, index) =>
                                            (
                                                <Table.Row style={{cursor: "pointer"}}
                                                           key={`key-${index}-${el._id}`}>

                                                    {hasRole(UserRole.ADMIN) &&
                                                    <Table.Cell textAlign={"center"}>
                                                        <Checkbox
                                                            checked={this.state.selectedPatientIds.includes(el._id, 0)}
                                                            onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                                ;

                                                                if (data.checked === true) {
                                                                    if (!this.state.selectedPatientIds.includes(el._id, 0))
                                                                        this.state.selectedPatientIds.push(el._id);
                                                                } else {
                                                                    if (this.state.selectedPatientIds.includes(el._id, 0))
                                                                        this.state.selectedPatientIds.splice(
                                                                            this.state.selectedPatientIds.indexOf(el._id), 1
                                                                        );
                                                                }
                                                                this.forceUpdate();
                                                            }}/>

                                                    </Table.Cell>}

                                                    <Table.Cell>
                                                        <Link
                                                            to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}
                                                            target="_blank"
                                                        >
                                                            {`${el.surname} ${el.name} ${el.middlename}`}
                                                        </Link>
                                                    </Table.Cell>

                                                    <MediaQuery minWidth={1224}>
                                                        <Table.Cell>
                                                            {el.doctorName}
                                                        </Table.Cell>

                                                        <Table.Cell textAlign={"center"}>
                                                            <Link className={styles.linkCell}
                                                                  to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}
                                                            >
                                                                {el.rheumatologistVisitDate !== null ? (el.rheumatologistVisitDate && new Date(el.rheumatologistVisitDate).toLocaleDateString("RU")) : ''}
                                                            </Link>
                                                        </Table.Cell>

                                                        <Table.Cell textAlign={"center"}>
                                                            <Link className={styles.linkCell}
                                                                  to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}
                                                            >
                                                                {el.hospitalizationDate !== null ? (el.hospitalizationDate && new Date(el.hospitalizationDate).toLocaleDateString("RU")) : ''}
                                                            </Link>
                                                        </Table.Cell>

                                                        <Table.Cell title={el.diagnosis}>
                                                            {/*<Link className={styles.linkCell}*/}
                                                            {/*      to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}*/}
                                                            {/*>*/}
                                                            {el.diagnosis?.substring(0, 50)}
                                                            {/*</Link>*/}
                                                        </Table.Cell>

                                                        <Table.Cell
                                                            title={el.specialgroupids ? this.state.specialgroups.filter(specialgroup => el.specialgroupids?.includes(specialgroup._id.toString())).map(specialgroup => specialgroup.name).join("; ") : ''}>
                                                            {el.specialgroupids ? this.state.specialgroups.filter(specialgroup => el.specialgroupids?.includes(specialgroup._id.toString())).map(specialgroup => specialgroup.name).join("; ").substring(0, 20) : ''}
                                                        </Table.Cell>

                                                        <Table.Cell>
                                                            {(el.activated && el.activated == true) ? 'Да' : 'Нет'}
                                                        </Table.Cell>

                                                        <Table.Cell>
                                                            <Link className={styles.linkCell}
                                                                  to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}
                                                            >
                                                                {el.dateofbirth && new Date(el.dateofbirth).toLocaleDateString("RU")}
                                                            </Link>
                                                        </Table.Cell>

                                                    </MediaQuery>
                                                    <Table.Cell textAlign={"right"}>
                                                        <Link className={styles.linkCell}
                                                              to={this.addPrevPathToLink(`${currentPath}/${el._id}/edit/patientInfo`)}
                                                        >
                                                            {el.tel && el.tel}
                                                        </Link>
                                                    </Table.Cell>
                                                    {hasRole(UserRole.ADMIN) &&
                                                    <Table.Cell>
                                                        <Button icon
                                                                onClick={() => this.setState({
                                                                    openRemoveModal: true,
                                                                    id: el._id
                                                                })}
                                                        >
                                                            <Icon color={"red"} name='trash alternate outline'/>
                                                        </Button>
                                                    </Table.Cell>}
                                                </Table.Row>
                                            )
                                        )
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <div></div>
                                        </Table.HeaderCell>

                                        <Table.HeaderCell colSpan='10'>
                                            <Menu floated='right' pagination>
                                                <TablePage
                                                    fetchData={this.getPatientsList}
                                                    totalPages={this.state.totalPages}
                                                    totalDocs={this.state.totalDocs}
                                                    prevPage={this.state.prevPage}
                                                    nextPage={this.state.nextPage}
                                                />

                                                {this.state.selectedPatientIds.length > 0 &&
                                                    <Menu.Item>
                                                {`Отмечено: ${this.state.selectedPatientIds.length}`}
                                                    </Menu.Item>
                                                }
                                            </Menu>
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>

                            </Table>
                            {/*</div>*/}
                        </div>
                }
            </div>
        )
    }


}

export default PatientsList;
