import React, { ChangeEvent } from 'react';
import { Button, Form } from 'semantic-ui-react';
import styles from "../index.module.css";

interface Option {
    value: string;
    text: string;
}

interface SearchableCheckboxListProps {
    label: string;
    options: Option[];
    selectedIds: string[];
    searchValue: string;
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onResetSearch: () => void;
    onSelectChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onClearSelection: () => void;
}

const SearchableCheckboxList: React.FC<SearchableCheckboxListProps> = ({
       label,
       options,
       selectedIds,
       searchValue,
       onSearchChange,
       onResetSearch,
       onSelectChange,
       onClearSelection
   }) => {
    return (
        <Form.Field>
            <label>{label}</label>
            <div className={styles.special_group_area}>
                <div className={styles.special_group_search}>
                    <input
                        type="text"
                        value={searchValue}
                        onChange={onSearchChange}
                        placeholder="Поиск"
                        style={{ width: '30%' }}
                    />
                    <Button
                        basic
                        color='orange'
                        content='Сбросить'
                        onClick={onResetSearch}
                        style={{ marginLeft: '10px' }}
                    />
                    <p style={{ marginLeft: '10px' }}>Выбрано: {selectedIds.length}</p>
                    {selectedIds.length > 0 &&
                        <Button
                            basic
                            color='grey'
                            content='Очистить значение'
                            onClick={onClearSelection}
                            style={{ marginLeft: '10px' }}
                        />
                    }
                </div>
                {options
                    .filter(option => option.text.toLowerCase().includes(searchValue.toLowerCase()))
                    .map(option => (
                        <label key={option.value} style={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}>
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedIds.includes(option.value)}
                                style={{ marginRight: '5px' }}
                                onChange={onSelectChange}
                            />
                            {option.text}
                        </label>
                    ))}
            </div>
        </Form.Field>
    );
};

export default SearchableCheckboxList;