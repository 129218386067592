import React from "react";
import styles from "./index.module.css";
import {Menu} from 'semantic-ui-react';
import {Route, Switch} from "react-router";
import {BrowserRouter, Link} from "react-router-dom";
import {Logout} from "../logout";
import PatientsList from "../workTablePatients/PatientsList";
import PatientEditor from "../workTablePatients/Editor";
import DoctorsList from "../workTableDoctors/DoctorsList";
import DoctorEditor from "../workTableDoctors/Editor";
import DeptList from "../workTableDepts/DeptList";
import DeptEditor from "../workTableDepts/Editor";
import ConfigPanel from "../configPanel";
import {hasRole, UserRole} from "../../helpers/UserRole";
import PatientInfoTab from "../workTablePatients/EditorTabs/PatientInfoTab";
import Hospitalizations from "../hospitalizations";
import {EventJournalRecord} from "../workTablePatients/EditorTabs/CureProgramTab";
import Deviations from "../deviations";
import PatientActivityList from "../workTablePatientActivity/PatientActivityList";
import UserFileTypeEditor from "../workTableUserFileTypes/Editor";
import UserFileTypeList from "../workTableUserFileTypes/UserFileTypeList";
import SpecialGroupList from "../workTableSpecialGroups/SpecialGroupList";
import MassiveEventList from "../workTablePatientMassiveEvents/MassiveEventList";
import TestListPage from "../test/TestListPage";
import TestEditPage from "../test/TestEditPage";
import {Utils} from "../../helpers/Utils";
import {
    AppMode,
    DoctorTitleCase,
    GeneralTitleCase,
    getDoctorTitle,
    getGeneralTitle,
    getPatientTitle,
    PatientTitleCase
} from "../../utils/common";
import AppointmentList from "../workTableAppointments/AppointmentList";
import AppointmentEditor from "../workTableAppointments/Editor";
import ObserversList from "../configPanel/workTableObserver/ObserversList";
import ObserverEditor from "../configPanel/workTableObserver/Editor";
import InstitutionsList from "../configPanel/workTableInstitution/InstitutionsList";
import InstitutionEditor from "../configPanel/workTableInstitution/Editor";
import TaskList from "../workTableTask/TaskList";

export interface Doctor {
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    username: string,
    deptId?: string,
    deptIds?: string[],
    deptName?: string,
    login?: string,
    password?: string,
    hasPassword?: boolean,
    roleEnableScan?: boolean,
    roleEnableFileUpload?: boolean
}

export interface Observer {
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    username: string,
    login?: string,
    password?: string,
    hasPassword?: boolean,
    roleEnableScan?: boolean,
    roleEnableFileUpload?: boolean
}

export interface Institution {
    _id: string,
    name: string,
    link?: string,
    active: boolean
}

export interface Dept {
    _id: string,
    name: string
}

export interface UserFileType {
    _id: string,
    name: string
}

export interface SpecialGroup {
    _id: string,
    name: string
}

export interface Appointment {
    _id: string,
    name: string
}

export interface MassiveEvent {
    _id: string,
    eventIds: string[],
    description: string,
    startDate: Date,
    endDate: Date,
    type: string,
    eventType: string,
    editDate: Date,
    status?: string
}

export interface Patient {
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    snils?: string,
    registrationaddress?: string,
    residenceaddress?: string,
    doctorid?: string,
    specialgroupids?: string[],
    dateofbirth: Date,
    registerDate?: Date,
    activatedDate?: Date,
    updateActivatedDate?: Date,
    autoActivated?: boolean,
    activatedDateHasBeenChanged?: boolean,
    diagnosis: string,
    code?: number,
    codeexpiredat?: Date,
    activated?: boolean,
    inactive?: boolean,
    inactiveUntil?: Date,
    diary?: string,
    isLargeFamily?: boolean,
    inn?: string,
    botblocked?: boolean,

    // для tambsu:
    institute?: string,
    specialization?: string,
    course?: string,
    educationForm?: string,
    fundingSource?: string,
    UID?: string,
    hasChildren?: boolean,
    structuralDivision?: string,
    post?: string
}

export interface ChangePatientDoctorsRequest {
    patientIds: string[],
    doctorId: string
}

export interface PatientsListItem {
    _id: string,
    name: string,
    middlename: string,
    surname: string,
    dateofbirth: Date,
    tel: string,
    eventConfirmStatus: EventConfirmStatus,
    rheumatologistVisitDate?: Date,
    hospitalizationDate?: Date,
    diagnosis?: string,
    doctorId?: string,
    doctorName?: string,
    specialgroupids?: string[],
    activated?: boolean
}

export interface PatientsActivityListItem {
    _id: string,
    time: Date,
    patientId: string,
    patientName: string,
    doctorId: string,
    doctorName: string,
    fileId?: string,
    fileName?: string,
    fileUploadDate?: Date,
    fileContentType?: string,
    type: string,
    // SivovaAlex-20240517-ActivitySettingsRollback
    // activityDays?: number,
    notificationStatus: string,
    notificationMessage?: string,
    notificationDate: string,
    isViewed: boolean,
    comment?: string
}

export interface DoctorOptions {
    key: string,
    text: string,
    value: string,
}

export interface DeptOptions {
    key: string,
    text: string,
    value: string,
}

export interface SpecialGroupOptions {
    key: string,
    text: string,
    value: string,
}

export interface PatientFile {
    _id: string,
    uploadDate: Date,
    filename: string,
    contentType: string,
    metadata:
        {
            fileTypeId?: string,
            isFromAdmin?: boolean
        } | undefined
}

export interface InteractionHistory {
    id: string,
    date: Date,
    comment: string,
    manual: boolean,
    dateUpdate: Date,
    userCreate: string,
    userUpdate: string
}

export interface Deviation {
    patientId: string,
    name: string,
    middlename: string,
    surname: string,
    journalRecords: EventJournalRecord[]
}
// SivovaAlex-20240517-ActivitySettingsRollback
// export interface ActivitySettings {
//     withoutAssignmentDays: number,
//     inactiveDays: number,
//     passDays: number,
// }

export enum EventConfirmStatus {
    NOT_FOUND = 'notFound',
    ALL_CONFIRMED = 'allConfirmed',
    ALL_DECLINED = 'allDeclined',
    HAS_DECLINED = 'hasDeclined',
}

interface State {
    activeTab: string,
}

interface Props {
    history?: { location: { pathname: string; } };

}

class Dashboard extends React.PureComponent<Props, State> {
    state: State = {
        activeTab: 'patients',
    };

    async componentDidMount() {
        const currentTab: string = this.props.history ? this.props.history.location.pathname : '';

        this.setState({activeTab: currentTab.split("/")[2]});
    };

    handleItemClick = (name: string): void => {
        this.setState({
            activeTab: name
        });
    };

    render() {
        const activeItem = this.state.activeTab;

        return (
            <BrowserRouter>
                <div className={styles.dashboard}>
                    <Menu className={styles.menu} tabular vertical stackable>
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/patients'}
                            content={getPatientTitle(PatientTitleCase.tab)}
                            active={activeItem === 'patients'}
                            onClick={() => {
                                this.handleItemClick('patients');
                            }}
                        />

                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/depts'}
                            content={getGeneralTitle(GeneralTitleCase.departmanttab)}
                            active={activeItem === 'depts'}
                            onClick={() => {
                                this.handleItemClick('depts');
                            }}
                        />
                        }
                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/userFileTypes'}
                            content={'Типы файлов'}
                            active={activeItem === 'userFileTypes'}
                            onClick={() => {
                                this.handleItemClick('userFileTypes');
                            }}
                        />}

                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/doctors'}
                            content={Utils.vomode()!=AppMode.STUDENT ? getDoctorTitle(DoctorTitleCase.tab) : <>Карьерные<br />советники</>}
                            active={activeItem === 'doctors'}
                            onClick={() => {
                                this.handleItemClick('doctors');
                            }}
                        />
                        }

                        {<Menu.Item
                            as={Link}
                            to={'/dashboard/hospitalizations'}
                            content={getGeneralTitle(GeneralTitleCase.hospitalizationstab)}
                            active={activeItem === 'hospitalizations'}
                            onClick={() => {
                                this.handleItemClick('hospitalizations')
                            }}
                        />}

                        {<Menu.Item
                            as={Link}
                            to={'/dashboard/deviations'}
                            content={'Отклонения'}
                            active={activeItem === 'deviations'}
                            onClick={() => {
                                this.handleItemClick('deviations')
                            }}
                        />}

                        {<Menu.Item
                            as={Link}
                            to={'/dashboard/patientActivity'}
                            content={'Активность'}
                            active={activeItem === 'patientActivity'}
                            onClick={() => {
                                this.handleItemClick('patientActivity')
                            }}
                        />}

                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) && Utils.vomode()==AppMode.PATIENT &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/test'}
                            content={getGeneralTitle(GeneralTitleCase.analysistab)}
                            active={activeItem === 'test'}
                            onClick={() => {
                                this.handleItemClick('test');
                            }}
                        />
                        }

                        <Menu.Item
                            as={Link}
                            to={'/dashboard/specialGroups'}
                            content={getGeneralTitle(GeneralTitleCase.specialgrouptab)}
                            active={activeItem === 'specialGroups'}
                            onClick={() => {
                                this.handleItemClick('specialGroups')
                            }}
                        />

                        <Menu.Item
                            as={Link}
                            to={'/dashboard/appointments'}
                            content={getGeneralTitle(GeneralTitleCase.appointmentstab)}
                            active={activeItem === 'appointments'}
                            className={activeItem === 'appointments' ? 'activeMenuItem' : ''}
                            onClick={() => {
                                this.handleItemClick('appointments')
                            }}
                        />

                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER) || hasRole(UserRole.DOCTOR)) &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/tasks'}
                            content={'Системные задания'}
                            active={activeItem === 'tasks'}
                            onClick={() => this.handleItemClick('tasks')}
                        />
                        }

                        {(hasRole(UserRole.ADMIN) || hasRole(UserRole.OBSERVER)) &&
                        <Menu.Item
                            as={Link}
                            to={'/dashboard/config'}
                            content={'Настройки'}
                            active={activeItem === 'config'}
                            onClick={() => this.handleItemClick('config')}
                        />
                        }

                        <Menu.Item
                            as={Link}
                            to={'/dashboard/logout'}
                            content={'Выйти'}
                            active={activeItem === 'logout'}
                            onClick={() => this.handleItemClick('logout')}
                        />
                    </Menu>

                    <Switch>

                        <Route path={'/dashboard/patients'} exact component={PatientsList}/>
                        <Route path={'/dashboard/patients/create'} component={PatientInfoTab}/>
                        <Route path={'/dashboard/patients/:id/edit'} component={PatientEditor}/>

                        <Route path={'/dashboard/doctors'} exact component={DoctorsList}/>
                        <Route path={'/dashboard/doctors/create'} component={DoctorEditor}/>
                        <Route path={'/dashboard/doctors/:id/edit'} component={DoctorEditor}/>

                        <Route path={'/dashboard/depts'} exact component={DeptList}/>
                        <Route path={'/dashboard/depts/create'} component={DeptEditor}/>
                        <Route path={'/dashboard/depts/:id/edit'} component={DeptEditor}/>

                        <Route path={'/dashboard/test'} exact component={TestListPage}/>
                        <Route path={'/dashboard/test/create'} component={TestEditPage}/>
                        <Route path={'/dashboard/test/:id/edit'} component={TestEditPage}/>

                        <Route path={'/dashboard/userFileTypes'} exact component={UserFileTypeList}/>
                        <Route path={'/dashboard/userFileTypes/create'} component={UserFileTypeEditor}/>
                        <Route path={'/dashboard/userFileTypes/:id/edit'} component={UserFileTypeEditor}/>

                        <Route path={'/dashboard/patientActivity'} exact component={PatientActivityList}/>

                        <Route path={'/dashboard/hospitalizations'} exact component={Hospitalizations}/>

                        <Route path={'/dashboard/deviations'} component={Deviations}/>

                        <Route path={'/dashboard/specialGroups'} exact component={SpecialGroupList}/>

                        <Route path={'/dashboard/appointments'} exact component={AppointmentList}/>
                        <Route path={'/dashboard/appointments/create'} component={AppointmentEditor}/>
                        <Route path={'/dashboard/appointments/:id/edit'} component={AppointmentEditor}/>

                        <Route path={'/dashboard/patientMassiveEvent/getList'} exact component={MassiveEventList}/>

                        <Route path={'/dashboard/config'} component={ConfigPanel}/>
                        <Route path={'/dashboard/observers'} exact component={ObserversList}/>
                        <Route path={'/dashboard/observers/create'} component={ObserverEditor}/>
                        <Route path={'/dashboard/observers/:id/edit'} component={ObserverEditor}/>
                        <Route path={'/dashboard/institutions'} exact component={InstitutionsList}/>
                        <Route path={'/dashboard/institutions/:id/edit'} component={InstitutionEditor}/>

                        <Route path={'/dashboard/tasks'} exact component={TaskList}/>

                        <Route path={'/dashboard/logout'} component={Logout}/>

                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default Dashboard;