import React, {ChangeEvent, useEffect, useState} from "react";
import {Menu, Icon, Dropdown, DropdownProps} from "semantic-ui-react";


interface PagingFooterProps {
    prevPage?: number,
    nextPage?: number,
    page?: number,
    limit?: number,
    totalPages?: number,
    totalDocs?: number,
    loadPage?: (page: number, limit?: number) => void;
}

const PagingFooter = (props: PagingFooterProps) => {

    const handleChange = (page?: number, limit?: number) => {
        if (props.loadPage)
            props.loadPage(page ?? props.page ?? 1, limit ?? props.limit);
    }

    return (
        <Menu floated='right' pagination>
            <Menu.Item
                as='a'
                icon
                onClick={() => handleChange(1)}>
                <Icon name='angle double left'/>
            </Menu.Item>

            {
                props.prevPage ?
                    <Menu.Item
                        as='a'
                        onClick={() => handleChange(props.prevPage)}>
                        {props.prevPage}
                    </Menu.Item>
                    :
                    ''
            }

            <Menu.Item
                style={{background: 'lightgray'}}
            >
                {props.page}
            </Menu.Item>

            {
                props.nextPage ?
                    <Menu.Item
                        as='a'
                        onClick={() => handleChange(props.nextPage)}>
                        {props.nextPage}
                    </Menu.Item>
                    :
                    ''
            }

            <Menu.Item
                as='a'
                icon
                onClick={() => handleChange(props.totalPages)}>
                <Icon name='angle double right'/>
            </Menu.Item>

            {props.limit &&
            <Dropdown
                text={`${props.limit} на странице`}
                options={[
                    {key: 1, text: '10', value: 10},
                    {key: 2, text: '25', value: 25},
                    {key: 3, text: '50', value: 50},
                    {key: 4, text: '100', value: 100},
                ]}
                item
                upward
                onChange={(e, data) => {
                    const limit = Number(data.value);
                    handleChange(1, limit)
                }
                }
            />}

            <Dropdown
                text={props.page?.toString() ?? "1"}
                scrolling={true}
                options={Array.from({length: (props.totalPages ?? 0)})
                    .map((_, i) => i + 1)
                    .map(i => ({key: i, text: i.toString(), value: i}))}
                item
                upward
                onChange={(e, data) => handleChange(Number(data.value))}
            />
            <Menu.Item>
                {`Страниц: ${props.totalPages !== undefined ? props.totalPages : 0}`}
            </Menu.Item>
            <Menu.Item>
                {`Записей: ${props.totalDocs}`}
            </Menu.Item>
        </Menu>
    );
}

export default PagingFooter;