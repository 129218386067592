import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Observer} from "../../dashboard";
import {Button, Form, Icon, Loader, Menu, Modal, Table} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../../agent";
import {Link} from "react-router-dom";
import TablePage, {setUrlParam} from "../../general/TablePage";

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    observers: Observer[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
}

export interface ObserverPaginationResponse {
    docs: Observer[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class ObserversList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        observers: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: '',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getObserversList();
    };

    getObserversList = async (goToPage?: number, sortBy?: string, searchString?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
        };

        const response: ObserverPaginationResponse = await agent.ObserverProvider.getList(paginateOptions);

        if (response) {
            setUrlParam("page", paginateOptions.page.toString());
            this.setState({
                observers: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    removeObserver = async () => {
        this.setState({loading: true});

        await agent.ObserverProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false
        });

        await this.getObserversList();
    };
    timeout: NodeJS.Timeout | undefined;

    observersSearch = (): void => {
        this.setState({loading: true});
        this.getObserversList(this.state.page, '', this.state.searchValue, this.state.limit);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getObserversList(this.state.page, sortString, this.state.searchValue, this.state.limit)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    render() {
        const createLabel: string = `Добавить наблюдателя`;
        const headerLabel: string = `Список наблюдателей`;
        const removeLabel: string = `УДАЛИТЬ`;
        const cancelLabel: string = `Отмена`;
        const confirmRemoveLabel: string = `Вы уверены, что хотите удалить наблюдателя?`;

        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {
                    this.state.openRemoveModal &&
                    <Modal
                        onClose={() => this.setState({openRemoveModal: false})}
                        open={this.state.openRemoveModal}
                        closeIcon
                    >
                        <Modal.Header>{confirmRemoveLabel}</Modal.Header>
                        <Modal.Actions>
                            <Button
                                content={removeLabel}
                                onClick={this.removeObserver}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openRemoveModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }

                <div className={styles.header}>
                    {headerLabel}

                    <Link to={`${currentPath}/create`}>
                        <Button
                            className={styles.createButton}
                            size={'large'}
                            color={'green'}
                            type={'submit'}
                        >
                            {createLabel}
                        </Button>
                    </Link>
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        placeholder='ФИО'
                                        iconPosition='left'
                                        icon={<Icon name='search'/>}
                                        value={this.state.searchValue}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            this.setState({searchValue: e.target.value})
                                        }}
                                    />
                                    <Form.Button primary content='Поиск' onClick={() => {
                                        this.observersSearch()
                                    }}/>
                                    <Form.Button basic color='orange' content='Сбросить'
                                                 onClick={() => {
                                                     this.setState({searchValue: ""}, this.observersSearch)
                                                 }}/>
                                </Form.Group>
                            </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'surname' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('surname')}
                                            >ФИО</Table.HeaderCell>
                                            <Table.HeaderCell/>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.observers && this.state.observers.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>

                                                        <Table.Cell floated={'left'}>
                                                            <Link className={styles.nameColumn}
                                                                  to={`${currentPath}/${el._id}/edit`}>
                                                                {`${el.surname} ${el.name} ${el.middlename}`}
                                                            </Link>
                                                        </Table.Cell>

                                                        <Table.Cell floated={'right'} style={{width: "5%"}}>
                                                            <Button icon
                                                                    onClick={() => this.setState({
                                                                        openRemoveModal: true,
                                                                        id: el._id
                                                                    })}
                                                            >
                                                                <Icon color={"red"} name='trash alternate outline'/>
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4'>
                                                <Menu floated='right' pagination>

                                                    <TablePage
                                                        fetchData={this.getObserversList}
                                                        totalPages={this.state.totalPages}
                                                        totalDocs={this.state.totalDocs}
                                                        prevPage={this.state.prevPage}
                                                        nextPage={this.state.nextPage}
                                                    />

                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default ObserversList;
