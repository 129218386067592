import React, {ChangeEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import {SpecialGroup} from "../dashboard";
import {
    Button,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Icon,
    Loader,
    Menu,
    Modal,
    Table
} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {Utils} from "../../helpers/Utils";
import {AppMode, GeneralTitleCase, getGeneralTitle} from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";
import {DoctorPaginationResponse} from "../workTableDoctors/DoctorsList";
import history from "../../history";
import TablePage, {setUrlParam} from "../general/TablePage";
import { textLabels } from "../../consts/textLabels";

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    specialGroups: SpecialGroup[],
    allSpecialGroups: SpecialGroup[],
    specialGroupsIdsFilter?: string[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
}

export interface SpecialGroupPaginationResponse {
    docs: SpecialGroup[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class SpecialGroupList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 0,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        specialGroups: [],
        allSpecialGroups: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: 'name',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getSpecialGroupAllList()

        await this.getSpecialGroupsList(1, 'name');

    };

    getSpecialGroupAllList = async (): Promise<void> => {
        this.setState({loading: true});

        const responseSpecialGroups: DoctorPaginationResponse = await agent.SpecialGroupProvider.getList();

        if (responseSpecialGroups) {
            this.setState({
                allSpecialGroups: responseSpecialGroups.docs
            });
        } else {
            this.setState({loading: false});
        }
    };

    getSpecialGroupsList = async (goToPage?: number, sortBy?: string, searchString?: string, limit?: number): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        sortBy = 'name'
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: limit ? limit : 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            //search: searchTo,
            specialGroupsIdsFilter: this.state.specialGroupsIdsFilter,
        };

        const response: SpecialGroupPaginationResponse = await agent.SpecialGroupProvider.getList(paginateOptions);

        if (response) {
            setUrlParam("page", paginateOptions.page.toString());
            this.setState({
                specialGroups: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false,
                searchValue: paginateOptions.search!,
            });
        } else {
            this.setState({loading: false});
        }
    };

    timeout: NodeJS.Timeout | undefined;

    specialGroupsSearch = (): void => {
        this.setState({loading: true});
        this.getSpecialGroupsList(this.state.page, '', this.state.searchValue, this.state.limit);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getSpecialGroupsList(this.state.page, sortString, this.state.searchValue, this.state.limit)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    setUrlParam = (key: string, value: string) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(key, value);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    handlePageLimitChange = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({limit: Number(data.value)}, () => {
            this.setUrlParam("limit", this.state.limit.toString());
            this.getSpecialGroupsList(1, this.state.sortColumn, this.state.searchValue, this.state.limit)
        });
    }

    render() {
        //const createLabel: string = `Добавить`;
        const headerLabel: string = `Список ${Utils.vomode()!=AppMode.STUDENT ? 'особых групп' : 'курсов'}`;
        // const removeLabel: string = `УДАЛИТЬ`;
        // const cancelLabel: string = `Отмена`;
        // const confirmRemoveLabel: string = `Вы уверены, что хотите удалить тип файла    ?`;
        //
        //const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {/*{*/}
                {/*    this.state.openRemoveModal &&*/}
                {/*    <Modal*/}
                {/*        onClose={() => this.setState({openRemoveModal: false})}*/}
                {/*        open={this.state.openRemoveModal}*/}
                {/*        closeIcon*/}
                {/*    >*/}
                {/*        <Modal.Header>{confirmRemoveLabel}</Modal.Header>*/}
                {/*        <Modal.Actions>*/}
                {/*            <Button*/}
                {/*                content={removeLabel}*/}
                {/*                onClick={this.removeSpecialGroup}*/}
                {/*                positive*/}
                {/*            />*/}
                {/*            <Button*/}
                {/*                color={'grey'}*/}
                {/*                onClick={() => this.setState({openRemoveModal: false})}*/}
                {/*            >*/}
                {/*                {cancelLabel}*/}
                {/*            </Button>*/}
                {/*        </Modal.Actions>*/}
                {/*    </Modal>*/}
                {/*}*/}

                <div className={styles.header}>
                    {headerLabel}
                    {/*<Link to={`${currentPath}/create`}>*/}
                    {/*    <Button*/}
                    {/*        className={styles.createButton}*/}
                    {/*        size={'large'}*/}
                    {/*        color={'green'}*/}
                    {/*        type={'submit'}*/}
                    {/*    >*/}
                    {/*        {createLabel}*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                                <Form>
                                    <Form.Group>
                                        <Form.Field width={"4"}>
                                            <Dropdown
                                                selection
                                                multiple
                                                search={true}
                                                clearable={true}
                                                value={this.state.specialGroupsIdsFilter}
                                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                    const selectedValues = data.value as string[];

                                                    this.setState({
                                                        specialGroupsIdsFilter: selectedValues.length > 0 ? selectedValues : undefined
                                                    }, this.specialGroupsSearch);
                                                }}
                                                options={this.state.allSpecialGroups.map((specialgroup) => ({
                                                    key: specialgroup._id,
                                                    text: specialgroup.name,
                                                    value: specialgroup._id,
                                                } as DropdownItemProps))}
                                                noResultsMessage={textLabels.noResultsMessage}
                                            />
                                        </Form.Field>
                                        <Form.Button primary content='Поиск' onClick={()=>{this.specialGroupsSearch()}} />
                                    </Form.Group>
                                </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'name' ? this.state.sortOrder : undefined}
                                                // onClick={() => this.sortColumn('name')}
                                            >Наименование {Utils.vomode()!=AppMode.STUDENT ? 'особой группы' : 'курса'}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.specialGroups && this.state.specialGroups.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>

                                                        <Table.Cell floated={'left'}>
                                                            {/*<Link className={styles.nameColumn}*/}
                                                            {/*      to={`${currentPath}/${el._id}/edit`}>*/}
                                                            {/*    {`${el.name}`}*/}
                                                            {/*</Link>*/}
                                                            {`${el.name}`}
                                                        </Table.Cell>

                                                        {/*<Table.Cell floated={'right'} style={{width: "5%"}}>*/}
                                                        {/*    <Button icon*/}
                                                        {/*            onClick={() => this.setState({*/}
                                                        {/*                openRemoveModal: true,*/}
                                                        {/*                id: el._id*/}
                                                        {/*            })}*/}
                                                        {/*    >*/}
                                                        {/*        <Icon color={"red"} name='trash alternate outline' />*/}
                                                        {/*    </Button>*/}
                                                        {/*</Table.Cell>*/}
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4'>
                                                <Menu floated='right' pagination>

                                                    <TablePage
                                                        fetchData={this.getSpecialGroupsList}
                                                        totalPages={this.state.totalPages}
                                                        totalDocs={this.state.totalDocs}
                                                        prevPage={this.state.prevPage}
                                                        nextPage={this.state.nextPage}
                                                    />

                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default SpecialGroupList;
