import React, {ChangeEvent, FormEvent, SyntheticEvent} from "react";
import styles from "../index.module.css";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin, {DateClickArg} from '@fullcalendar/interaction';
import ruLocale from '@fullcalendar/core/locales/ru';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import {
    Button,
    ButtonGroup,
    Segment,
    Icon,
    Radio,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    InputOnChangeData,
    Loader,
    Modal,
    TextAreaProps,
} from "semantic-ui-react";
import ReactInputMask from "react-input-mask";
import {
    EventRepeater, getEventTypesOptions,
    getPatientDailyEventEndDate,
    getPatientMonthlyEventEndDate,
    getPatientWeeklyEventEndDate, getRepeatTypesOptions,
    handleDatePickerKeyDown
} from "../../../helpers/CalendarEventsRepeater";
import agent, {PaginateOptions} from "../../../agent";
import {
    AppMode,
    DoctorTitleCase,
    GeneralTitleCase,
    getDoctorTitle,
    getGeneralTitle,
    getTZ,
    safeParseInt
} from "../../../utils/common";
import {Appointment, Patient} from "../../dashboard";
import {Utils} from "../../../helpers/Utils";
import MediaQuery from "react-responsive";
import {EventClickArg} from "@fullcalendar/core";
import moment from "moment";
import {AppointmentPaginationResponse} from "../../workTableAppointments/AppointmentList";
import {hasRole, UserRole} from "../../../helpers/UserRole";
import {FinishTypes, EventTypes, RepeatTypes, DaysOfWeek } from "../../../enums/EventEnums"
import {
    daysCheckboxes,
    buildCancellationTimeframeFrom,
    buildCancellationTimeframeTo
} from "../../../consts/EventConsts"
import { textLabels } from "../../../consts/textLabels";
import EditableText from "../../general/EditableText/EditableText"

interface Props {
    id: string;
}

type TypeDescriptionFormatting = 'bold' | 'italic' | 'underline';

interface State {
    eventId: string,
    openModal: boolean,
    events: CalendarEvent[],
    description: string,
    descriptionFormatting: boolean,
    descriptionTypeFormatting: TypeDescriptionFormatting,
    appointment?: boolean | null,
    appointmentId?: string,
    appointmentList: Appointment[],
    eventType: string,
    repeatType: string,
    finishType: string,
    repeatByDaysCount: number,
    repeatByWeeksCount: number,
    repeatByMonthsCount: number,
    repeatSeveralTimesCount: number,
    repeatDays: string[],
    checkboxRepeatDays: Map<string, boolean>,
    startDate?: Date,
    endDate?: Date,
    cancellationTimeframeFrom: Date | null,
    cancellationTimeframeTo: Date | null,
    massive?: boolean | null,
    time: string,
    loading: boolean,
    editMode: boolean,
    journal: EventJournalRecord[],
    checkboxSelectedItemsSwitch: boolean,
    currentCalendarDate: Date,
    eventHasBeenChanged: boolean,
    cancellationTimeframeHasBeenChanged: boolean,
    prevState: State | null,
    calendarSelectedDate: Date | null,
    filterEventType: string | undefined;
}

export interface CalendarEvent {
    groupId: string,
    title: string,
    start: string,
    backgroundColor?: string,
    url?: string
}

export interface PatientEvent {
    _id: string,
    patientId: string,
    startDate: Date,
    endDate: Date,
    eventType: string,
    finishType: string,
    repeatType: string,
    repeatSeveralTimesCount: number,
    repeatByDaysCount: number,
    repeatByWeeksCount: number,
    repeatByMonthsCount: number,
    repeatDays: string[],
    description: string,
    appointment?: boolean | null,
    appointmentId?: string,
    journal: EventJournalRecord[],
    cancellationTimeframeFrom: Date | null,
    cancellationTimeframeTo: Date | null,
    massive?: boolean | null,
}

export interface PatientEventFull extends PatientEvent {
    patient: Patient,
}

interface DropdownOptions {
    key: string,
    text: string,
    value: string,
}

export interface EventJournalRecord {
    id: string,
    status: string,
    date: Date,
    description: string,
    statusDate?: Date
}

class CureProgramTab extends React.PureComponent<Props, State> {

    state: State = {
        eventId: '',
        openModal: false,
        events: [],
        repeatType: '',
        eventType: '',
        description: '',
        descriptionFormatting: false,
        descriptionTypeFormatting: 'bold',
        appointment: false,
        appointmentId: '',
        appointmentList: [],
        finishType: FinishTypes.COUNTS,
        repeatSeveralTimesCount: 0,
        repeatByWeeksCount: 0,
        repeatByDaysCount: 0,
        repeatDays: [],
        checkboxRepeatDays: new Map(),
        cancellationTimeframeFrom: null,
        cancellationTimeframeTo: null,
        massive: false,
        time: '',
        loading: false,
        editMode: true,
        journal: [],
        checkboxSelectedItemsSwitch: false,
        currentCalendarDate: new Date(),
        eventHasBeenChanged: false,
        cancellationTimeframeHasBeenChanged: false,
        prevState: null,
        calendarSelectedDate: null,
        repeatByMonthsCount: 1,
        filterEventType: undefined
    };

    clearState = (): void => {
        this.setState({
            eventId: '',
            openModal: false,
            repeatType: '',
            eventType: '',
            description: '',
            descriptionFormatting: false,
            descriptionTypeFormatting: 'bold',
            appointment: false,
            appointmentId: '',
            finishType: FinishTypes.COUNTS,
            repeatSeveralTimesCount: 0,
            repeatByWeeksCount: 0,
            repeatByDaysCount: 0,
            repeatDays: [],
            checkboxRepeatDays: new Map(),
            time: '',
            editMode: true,
            journal: [],
            checkboxSelectedItemsSwitch: false,
            cancellationTimeframeFrom: null,
            cancellationTimeframeTo: null,
            massive: false,
            eventHasBeenChanged: false,
            cancellationTimeframeHasBeenChanged: false,
            prevState: null,
            calendarSelectedDate: null
        });
    };

    async componentDidMount() {
        // await this.load();
        this.setState({loading: true});
        await this.getEvents();
        await this.getAppointmentList();
    };

    async load() {
        this.setState({loading: true});
        await this.getEvents();
    };

    getAppointmentList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "name"
        };

        const response: AppointmentPaginationResponse = await agent.AppointmentProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                appointmentList: response.docs
            });
        }
        this.setState({loading: false});
    };

    onChangeRepeatingDays = (e: any, data: CheckboxProps) => {
        const item = data.value as string;
        const isChecked = data.checked as boolean;

        this.setState((prevState) => {
            return {
                checkboxRepeatDays: prevState.checkboxRepeatDays.set(item, isChecked),
                checkboxSelectedItemsSwitch: !prevState.checkboxSelectedItemsSwitch
            }
        });
    };

    eventDateLabel: string = 'Дата';
    eventStartDateLabel: string = 'Дата начала';

    getEndDate = (): Date | undefined => {
        if (this.state.repeatType === RepeatTypes.NO_REPEATABLE) {
            return this.state.startDate;
        }
        if (this.state.finishType === FinishTypes.DATE && this.state.endDate) {
            return this.state.endDate;
        } else if (this.state.finishType === FinishTypes.COUNTS && this.state.repeatSeveralTimesCount && this.state.startDate) {
            if (this.state.repeatType === RepeatTypes.DAILY) {
                return getPatientDailyEventEndDate(this.state.startDate, this.state.repeatByDaysCount, this.state.repeatSeveralTimesCount);
            }

            if (this.state.repeatType === RepeatTypes.WEEKLY) {
                return getPatientWeeklyEventEndDate(this.state.startDate, this.state.repeatByWeeksCount, this.state.repeatSeveralTimesCount, this.getSelectedDaysOfWeek());
            }
            if (this.state.repeatType === RepeatTypes.MONTHLY) {
                return getPatientMonthlyEventEndDate(this.state.startDate, this.state.repeatByMonthsCount, this.state.repeatSeveralTimesCount);
            }
        }
        return undefined;
    };

    startCreateEvent = async () => {
        this.setState({
            startDate: new Date(),
            openModal: true,
            editMode: false,
            calendarSelectedDate: new Date(),
        });
    }

    onCreateEvent = async (): Promise<void> => {
        await agent.PatientEventProvider.create({
            _id: '',
            patientId: this.props.id,
            description: this.state.description,
            appointment: this.state.appointment ? this.state.appointment : false,
            appointmentId: this.state.appointmentId ? this.state.appointmentId : '',
            startDate: this.state.startDate ? this.setTimeForDate(this.state.startDate!, this.state.time) : undefined,
            endDate: this.setTimeForDate(this.getEndDate()!, this.state.time),
            cancellationTimeframeFrom: buildCancellationTimeframeFrom(this.state.cancellationTimeframeFrom),
            cancellationTimeframeTo: buildCancellationTimeframeTo(this.state.cancellationTimeframeTo),
            eventType: this.state.eventType,
            finishType: this.state.finishType,
            repeatType: this.state.repeatType,
            repeatSeveralTimesCount: this.state.repeatSeveralTimesCount,
            repeatByDaysCount: this.state.repeatByDaysCount,
            repeatByWeeksCount: this.state.repeatByWeeksCount,
            repeatByMonthsCount: this.state.repeatByMonthsCount,
            repeatDays: this.getSelectedDaysOfWeek(),
            journal: this.state.journal,
        });
    };

    saveAndKeep = async (): Promise<void> => {
        await this.onCreateEvent();
        await this.setState({
            eventId: '',
            description: ''
        });
        await this.getEvents();
        alert("Назначение создано. Введите наименование для создания нового назначения с такими же параметрами.")

    }

    saveAndClose = async (): Promise<void> => {
        await this.onCreateEvent();
        this.clearState();
        await this.getEvents();
    }

    onEditEvent = async (): Promise<void> => {
        if (this.state.startDate && this.getEndDate()) {
            await agent.PatientEventProvider.edit({
                _id: this.state.eventId,
                patientId: this.props.id,
                description: this.state.description,
                appointment: this.state.appointment,
                appointmentId: this.state.appointmentId,
                startDate: this.setTimeForDate(this.state.startDate!, this.state.time),
                endDate: this.setTimeForDate(this.getEndDate()!, this.state.time),
                eventType: this.state.eventType,
                finishType: this.state.finishType,
                repeatType: this.state.repeatType,
                repeatSeveralTimesCount: this.state.repeatSeveralTimesCount,
                repeatByDaysCount: this.state.repeatByDaysCount,
                repeatByWeeksCount: this.state.repeatByWeeksCount,
                repeatByMonthsCount: this.state.repeatByMonthsCount,
                repeatDays: this.getSelectedDaysOfWeek(),
                journal: this.state.journal,
                cancellationTimeframeFrom: buildCancellationTimeframeFrom(this.state.cancellationTimeframeFrom),
                cancellationTimeframeTo: buildCancellationTimeframeTo(this.state.cancellationTimeframeTo),
            });

            this.clearState();

            await this.getEvents();
        }
    };

    onOpenEvent = async (eventId: string): Promise<void> => {
        this.setState({/*loading: true,*/ editMode: true, eventId: eventId});

        await agent.PatientEventProvider.open(eventId).then((patientEvent: PatientEvent): void => {
            if (patientEvent) {
                this.setState({
                    openModal: true,
                    repeatType: patientEvent.repeatType,
                    eventType: patientEvent.eventType,
                    description: patientEvent.description,
                    appointment: patientEvent.appointment,
                    appointmentId: patientEvent.appointmentId,
                    finishType: patientEvent.finishType,
                    repeatSeveralTimesCount: patientEvent.repeatSeveralTimesCount,
                    repeatByWeeksCount: patientEvent.repeatByWeeksCount,
                    repeatByDaysCount: patientEvent.repeatByDaysCount,
                    repeatDays: patientEvent.repeatDays,
                    checkboxRepeatDays: this.getCheckedCheckboxes(patientEvent.repeatDays),
                    startDate: new Date(patientEvent.startDate.toString()),
                    endDate: new Date(patientEvent.endDate.toString()),
                    cancellationTimeframeFrom: patientEvent.cancellationTimeframeFrom,
                    cancellationTimeframeTo: patientEvent.cancellationTimeframeTo,
                    time: this.getEventTime(new Date(patientEvent.startDate)),
                    massive: patientEvent.massive,
                    journal: patientEvent.journal,
                    loading: false,
                    editMode: true,
                }, () => {
                    this.setState({prevState: this.state})
                });
            }
        });
    };

    getEventTime = (date: Date): string => {
        return moment(date).tz(getTZ()).format("HH:mm");
    };

    setTimeForDate = (date: Date, time: string): Date => {
        const timeArray: string[] = time.split(':');
        return moment.tz([date.getFullYear(), date.getMonth(), date.getDate(),
            safeParseInt(timeArray[0]), safeParseInt(timeArray[1])], getTZ()).toDate();
    };

    onRemoveEvent = async (): Promise<void> => {
        await agent.PatientEventProvider.remove(this.state.eventId);

        this.clearState();

        await this.getEvents();
    };

    getCheckedCheckboxes = (checkboxes: string[]): Map<string, boolean> => {
        const checkboxesMap: Map<string, boolean> = new Map<string, boolean>();

        checkboxes.forEach((el: string) => {
            checkboxesMap.set(el, true);
        })

        return checkboxesMap;
    };

    onChangeFinishType = (e: FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
        this.setState({finishType: data.value as string});

        if (data.value === FinishTypes.DATE) {
            this.setState({
                repeatSeveralTimesCount: 0,
                eventHasBeenChanged: true
            });
        } else if (data.value === FinishTypes.COUNTS) {
            this.setState({
                endDate: undefined,
                eventHasBeenChanged: true
            });
        }
    };

    finishTypeDateCheckbox = (label: string | undefined = undefined) => {
        return (
            <Form.Group className={styles.formField}>
                <Checkbox
                    disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                    className={styles.radioCheckbox}
                    radio
                    name={'checkboxRadioGroup'}
                    value={FinishTypes.DATE}
                    checked={this.state.finishType === FinishTypes.DATE}
                    onChange={this.onChangeFinishType}
                />
                <div className={styles.endDate}>
                    <label> {label ?? "Дата"} </label>
                <div style={{marginLeft: "6px"}}>
                    <DatePicker
                        selected={this.state.finishType === FinishTypes.DATE && this.state.endDate ? new Date(this.state.endDate) : null}
                        onChange={(date: Date) => {
                            this.setState({
                                endDate: date,
                                eventHasBeenChanged: true
                            })
                        }}
                        onKeyDown={handleDatePickerKeyDown}
                        locale={ru}
                        dateFormat="dd.MM.yyyy"
                        disabled={(this.state.finishType !== FinishTypes.DATE) || (hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false))}
                    /></div>
                </div>
            </Form.Group>
        )
    };

    afterRepeatCountsCheckbox = () => {
        return (
            <>
                <label> После </label>
                <Form.Input width={2}
                            value={(this.state.finishType === FinishTypes.COUNTS && this.state.repeatSeveralTimesCount !== 0) ? this.state.repeatSeveralTimesCount : ''}
                            disabled={(this.state.finishType !== FinishTypes.COUNTS) || (hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false))}
                            error={this.isRepeatSeveralTimesError()}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({
                                    repeatSeveralTimesCount: safeParseInt(data.value as string),
                                    eventHasBeenChanged: true
                                })
                            }}
                />
                <label> повторений</label>
            </>
        )
    };

    getDateFields = (): JSX.Element => {
        switch (this.state.repeatType) {
            case RepeatTypes.NO_REPEATABLE: {
                return (
                    <Form.Field width={3}>
                        <label>{this.eventDateLabel}</label>
                        <DatePicker
                            disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                            selected={this.state.startDate ? new Date(this.state.startDate) : null}
                            onChange={(date: Date) => {
                                this.setState({
                                    startDate: date,
                                    eventHasBeenChanged: true
                                })
                            }}
                            onKeyDown={handleDatePickerKeyDown}
                            locale={ru}
                            dateFormat="dd.MM.yyyy"
                        />
                    </Form.Field>
                )
            }

            case RepeatTypes.DAILY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                onKeyDown={handleDatePickerKeyDown}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждый </label>
                            <Form.Input width={2}
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        value={this.state.repeatByDaysCount === 0 ? '' : this.state.repeatByDaysCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                            this.setState({
                                                repeatByDaysCount: safeParseInt(data.value as string),
                                                eventHasBeenChanged: true
                                            })
                                        }}
                            />
                            <label> день</label>
                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox()
                        }
                    </>
                )
            }

            case RepeatTypes.WEEKLY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                onKeyDown={handleDatePickerKeyDown}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждую </label>
                            <Form.Input width={2}
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        value={this.state.repeatByWeeksCount === 0 ? '' : this.state.repeatByWeeksCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({repeatByWeeksCount: safeParseInt(data.value as string)})}

                            />
                            <label> неделю</label>
                        </Form.Group>

                        <Form.Group>
                            {
                                daysCheckboxes.map(item => (
                                    <Checkbox
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        className={styles.checkboxItem}
                                        key={item.key}
                                        label={item.label}
                                        value={item.name}
                                        defaultChecked={this.state.checkboxRepeatDays.get(item.name)}
                                        onChange={this.onChangeRepeatingDays}
                                    />
                                ))
                            }

                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox()
                        }
                    </>

                )
            }

            case RepeatTypes.MONTHLY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                onKeyDown={handleDatePickerKeyDown}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждый </label>
                            <Form.Input width={2}
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        value={this.state.repeatByMonthsCount === 0 ? '' : this.state.repeatByMonthsCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                            this.setState({
                                                repeatByMonthsCount: safeParseInt(data.value as string),
                                                eventHasBeenChanged: true
                                            })
                                        }}
                            />
                            <label> месяц</label>
                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox("До даты")
                        }
                    </>
                )
            }

            default: {
                return (
                    <></>
                )
            }
        }
    };

    getEvents = async (): Promise<void> => {
        let calendarEvents: CalendarEvent[] = [];

        const eventDescriptionList: PatientEvent[] = await agent.PatientEventProvider.getList(this.props.id, this.state.filterEventType);
        if (eventDescriptionList) {
            eventDescriptionList.forEach((item: PatientEvent) => {
                const eventArray = EventRepeater(item);
                eventArray.forEach((event: CalendarEvent) => {
                    event.title = event.title.replace(/<[^>]*>/g, '');
                });
                calendarEvents.push(...eventArray);
            });
            this.setState({events: calendarEvents, loading: false});
        } else {
            this.setState({loading: false});
        }
    };

    getSelectedDaysOfWeek = (): string[] => {
        let daysArray: string[] = [];

        this.state.checkboxRepeatDays.forEach((value: boolean, key: string) => {
            if (value) {
                daysArray.push(key);
            }
        })

        return daysArray;
    };

    isRepeatSeveralTimesError = (): boolean => {
        return (this.state.repeatSeveralTimesCount > 500);
    }

    isEndDateError = (): boolean => {
        return !this.getEndDate() || (this.getEndDate()! > (new Date(2100, 1, 1)));
    }

    isStartDateError = (): boolean => {
        return !this.state.startDate || (this.state.startDate > (new Date(2100, 1, 1)));
    }

    isSaveDisabled = (): boolean => {
        const date = new Date();
        const now = moment.tz([date.getFullYear(), date.getMonth(), date.getDate(),
            date.getHours(), date.getMinutes()], getTZ()).toDate();
        const isValidCancellationTimeframeFields = (): boolean => {
            const isEmptyField = (value: any): boolean => {
                return value === null || value === 'Invalid Date';
            }

            return isEmptyField(this.state.cancellationTimeframeFrom) === isEmptyField(this.state.cancellationTimeframeTo);
        }

        if (this.state.eventId &&
            !this.state.eventHasBeenChanged &&
            this.state.cancellationTimeframeHasBeenChanged &&
            isValidCancellationTimeframeFields()
        ) {
            console.log(`isSaveDisabled return false 1`);
            return false;
        }

        if (this.isRepeatSeveralTimesError() || this.isEndDateError()) {
            console.log(`isSaveDisabled return true 1`);
            return true;
        }

        if (this.isStartDateError()) {
            console.log(`isSaveDisabled return true 2`);
            return true;
        }

        if (this.state.prevState) {
            if (
                this.state.eventType === this.state.prevState.eventType &&
                this.state.description === this.state.prevState.description &&
                this.state.repeatType === this.state.prevState.repeatType &&
                this.state.repeatByDaysCount === this.state.prevState.repeatByDaysCount &&
                this.state.time === this.state.prevState.time &&
                this.state.repeatByWeeksCount === this.state.prevState.repeatByWeeksCount &&
                this.state.repeatByMonthsCount === this.state.prevState.repeatByMonthsCount &&
                this.state.repeatSeveralTimesCount === this.state.prevState.repeatSeveralTimesCount &&
                this.state.startDate?.toDateString() === this.state.prevState.startDate?.toDateString() &&
                this.state.endDate?.toDateString() === this.state.prevState.endDate?.toDateString()
            ) {
                console.log(`isSaveDisabled return true 3`);
                return true;
            }
        }

        if (this.state.prevState) {
            if (
                this.state.eventHasBeenChanged &&
                this.state.eventType === this.state.prevState.eventType &&
                this.state.description === this.state.prevState.description &&
                this.state.repeatType === this.state.prevState.repeatType &&
                this.state.repeatByDaysCount === this.state.prevState.repeatByDaysCount &&
                this.state.time === this.state.prevState.time &&
                this.state.repeatByWeeksCount === this.state.prevState.repeatByWeeksCount &&
                this.state.repeatByMonthsCount === this.state.prevState.repeatByMonthsCount &&
                this.state.repeatSeveralTimesCount === this.state.prevState.repeatSeveralTimesCount &&
                this.state.startDate?.toDateString() === this.state.prevState.startDate?.toDateString() &&
                this.state.endDate && this.setTimeForDate(this.state.endDate, this.state.time) > now
            ) {
                console.log(`isSaveDisabled return false 2`);
                return false;
            }
        }

        let isRepeatTypeFilled: boolean = false;

        switch (this.state.repeatType) {
            case RepeatTypes.NO_REPEATABLE: {
                isRepeatTypeFilled = true;

                break
            }
            case RepeatTypes.DAILY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByDaysCount && !!this.state.repeatSeveralTimesCount;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByDaysCount && !!this.state.endDate && this.state.endDate > now;
                }

                break
            }

            case RepeatTypes.WEEKLY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByWeeksCount && !!this.state.repeatSeveralTimesCount && this.getSelectedDaysOfWeek().length > 0;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByWeeksCount && !!this.state.endDate && this.state.endDate > now && this.getSelectedDaysOfWeek().length > 0;
                }

                break
            }

            case RepeatTypes.MONTHLY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByMonthsCount && !!this.state.repeatSeveralTimesCount;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByMonthsCount && !!this.state.endDate && this.state.endDate > now;
                }

                break
            }
        }

        let result = !isValidCancellationTimeframeFields() ||
            !this.state.repeatType ||
            !this.state.eventType ||
            !this.state.description ||
            (!!this.state.startDate && this.setTimeForDate(this.state.startDate, this.state.time) < now) ||
            !(this.state.time && !this.state.time.includes('_')) ||
            !isRepeatTypeFilled;
        console.log(`isSaveDisabled = ${result}`);

        return result;
    };

    getDefaultTime = (): string => {
        if (this.state.time) {
            return this.state.time;
        } else {
            this.setState({time: '08:00'});

            return '08:00';
        }
    };



    render() {
        const modalCreateHeader: string = 'Создать задание';
        const modalEditHeader: string = 'Изменение задания';
        const modalShowMassiveHeader: string = 'Просмотр параметров массовой рассылки'


        return (
            <div className={styles.container}>
                {
                    this.state.openModal &&
                    <Modal
                        onClose={this.clearState}
                        open={this.state.openModal}
                        closeOnDocumentClick={false}
                        closeOnPortalMouseLeave={false}
                        closeOnTriggerMouseLeave={false}
                        closeOnEscape={false}
                    >
                        <Modal.Header>
                            {/*{this.state.editMode ? modalEditHeader : modalCreateHeader}*/}
                            {this.state.massive ? modalShowMassiveHeader : (this.state.editMode ? modalEditHeader : modalCreateHeader)}
                            {
                                this.state.calendarSelectedDate &&
                                <span style={{
                                    float: "right",
                                    display: "block"
                                }}>{this.state.calendarSelectedDate.toLocaleDateString()}</span>
                            }
                        </Modal.Header>

                        <Modal.Content>
                            <Form>

                                <Form.Field width={4}>
                                    <label>Тип задания</label>
                                    <Dropdown
                                        disabled={hasRole(UserRole.OBSERVER) || (hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false))}
                                        placeholder={'Выберите тип задания'}
                                        selection
                                        options={getEventTypesOptions()}
                                        defaultValue={this.state.eventType}
                                        onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            this.setState({
                                                eventType: data.value as string,
                                                eventHasBeenChanged: true
                                            })
                                        }}
                                    />
                                </Form.Field>

                                <Form.Checkbox
                                    label={getGeneralTitle(GeneralTitleCase.appointmentstab)}
                                    disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                    checked={this.state.appointment ? this.state.appointment : false}
                                    onChange={(e, data) => {
                                        this.setState({ appointment: data.checked ? true : false });
                                        if (!data.checked) this.setState({ appointmentId: '' });
                                    }}
                                />
                                {this.state.appointment ?
                                    <Form.Field width={8}>
                                        <label>Описание задания</label>
                                        <Dropdown
                                            disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                            placeholder={`Выберите ${getGeneralTitle(GeneralTitleCase.appointmentsim)}`}
                                            value={this.state.appointmentId}
                                            selection
                                            search
                                            noResultsMessage={textLabels.noResultsMessage}
                                            options={this.state.appointmentList.map((el) => ({
                                            key: el._id,
                                            text: el.name,
                                            value: el._id,
                                            } as DropdownItemProps))}
                                            onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                let support = this.state.appointmentList.filter((el) => el._id === data.value);
                                                this.setState({
                                                    appointmentId: support[0]._id,
                                                    description: support[0].name,
                                                    eventHasBeenChanged: true
                                                })
                                            }}
                                        />
                                    </Form.Field>
                                    :
                                    <div>
                                        <Form.Field width={8}>
                                            <label>
                                                Описание задания <i
                                                className="info circle icon grey"
                                                title="Для применения параметров форматирования необходимо: выделить часть текста и нажать Ctrl+B для выделения жирным, Ctrl+I для выделения курсивом, Ctrl+U для подчёркивания текста."></i>
                                            </label>
                                            <EditableText
                                                description={this.state.description}
                                                onChange={(newDescription) =>
                                                    this.setState({
                                                        description: newDescription,
                                                        eventHasBeenChanged: true
                                                    })
                                                }
                                                maxLength={300}
                                                disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                            />
                                       </Form.Field>
                                    </div>
                                }

                                <Form.Field width={4}>
                                    <label>Повторение</label>
                                    <Dropdown
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        placeholder={'Выберите тип повторения'}
                                        selection
                                        options={getRepeatTypesOptions()}
                                        defaultValue={this.state.repeatType}
                                        onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            this.setState({
                                                repeatType: data.value as string,
                                                eventHasBeenChanged: true
                                            })
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Исключение</label>
                                    <div style={{ display: "flex" }}><div style={{ width: "161px" }}>
                                        <DatePicker
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        selected={this.state.cancellationTimeframeFrom ? new Date(this.state.cancellationTimeframeFrom) : null}
                                        onChange={(date: Date) => {
                                                this.setState({
                                                    cancellationTimeframeFrom: date,
                                                    cancellationTimeframeHasBeenChanged: true
                                                })
                                        }}
                                        onKeyDown={handleDatePickerKeyDown}
                                        locale={ru}
                                        dateFormat="dd.MM.yyyy"
                                        //style={{ width: "161px" }}
                                    /></div>
                                    <span style={{lineHeight: "38px"}}> - </span>
                                    <div style={{ width: "161px" }}>
                                        <DatePicker
                                        disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}
                                        selected={this.state.cancellationTimeframeTo ? new Date(this.state.cancellationTimeframeTo) : null}
                                        onChange={(date: Date) => {
                                            this.setState({
                                                cancellationTimeframeTo: date,
                                                cancellationTimeframeHasBeenChanged: true
                                            })
                                        }}
                                        onKeyDown={handleDatePickerKeyDown}
                                        locale={ru}
                                        dateFormat="dd.MM.yyyy"
                                        //style={{ width: "161px" }}
                                    /></div></div>
                                </Form.Field>

                                {
                                    this.state.repeatType && this.getDateFields()
                                }

                                <Form.Field width={3}>
                                    <label>Время</label>
                                </Form.Field>
                                <Form.Field
                                    width={2}
                                    disabled={hasRole(UserRole.OBSERVER) || (this.state.massive ? this.state.massive : false)}>
                                    <ReactInputMask
                                        mask={'99:99'}
                                        alwaysShowMask={false}
                                        value={this.state.time}
                                        style={{ margin: "0 0 10em 0" }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            let time: string = e.target.value;
                                            const hours = time.substring(0, 2);
                                            const minutes = time.substring(3, 5);
                                            if (parseInt(hours, 10) >= 24) {
                                                time = `23:${minutes}`;
                                            } else if (parseInt(minutes, 10) >= 60) {
                                                time = `${hours}:59`;
                                            }
                                            this.setState({
                                                time: time,
                                                eventHasBeenChanged: true
                                            });
                                        }}
                                    />
                                </Form.Field>

                            </Form>

                        </Modal.Content>

                        <Modal.Actions>
                            {
                                this.state.editMode ?
                                    (
                                        !(this.state.massive || hasRole(UserRole.OBSERVER)) && (
                                            <>
                                                <Button
                                                    color={'yellow'}
                                                    content={'Удалить'}
                                                    onClick={this.onRemoveEvent}
                                                />
                                                <Button
                                                    content={'Изменить'}
                                                    disabled={this.isSaveDisabled()}
                                                    onClick={this.onEditEvent}
                                                    positive
                                                />
                                            </>)
                                    )
                                    :
                                    <>
                                        <Button
                                            content={'Сохранить и продолжить'}
                                            disabled={this.isSaveDisabled()}
                                            onClick={this.saveAndKeep}
                                            color={'blue'}
                                        />
                                        <Button
                                            content={'Сохранить и закрыть'}
                                            disabled={this.isSaveDisabled()}
                                            onClick={this.saveAndClose}
                                            positive
                                        />
                                    </>
                            }

                            <Button
                                content={'Отмена'}
                                color={'grey'}
                                onClick={this.clearState}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {
                    this.state.loading ?
                        <Loader active/>
                        :
                        <div>
                            <Form>
                                <Form.Group className={styles.formField}>
                                    {!hasRole(UserRole.OBSERVER) && <Form.Button onClick={this.startCreateEvent}
                                                 color={'green'}
                                                 type={'submit'}>
                                        <label>
                                            Добавить
                                        </label>
                                    </Form.Button>}
                                    <Form.Field width={"3"}>
                                        <label>
                                            Тип заданий
                                        </label>
                                        <Dropdown
                                            selection
                                            clearable={true}
                                            value={this.state.filterEventType}
                                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                this.setState({filterEventType: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                                    this.getEvents)
                                            }}
                                            options={getEventTypesOptions()}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>

                            <MediaQuery minWidth={1224}>
                                <FullCalendar
                                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay,month,year'

                                    }}
                                    views={{
                                        year: {
                                            type: 'listYear',
                                            buttonText: 'Повестка год'
                                        },
                                        month: {
                                            type: 'listMonth',
                                            buttonText: 'Повестка месяц'
                                        }
                                    }}
                                    nextDayThreshold={'02:00:00'}
                                    locale={ruLocale}
                                    displayEventTime={true}
                                    initialView="dayGridMonth"
                                    events={this.state.events}
                                    eventClick={(e: EventClickArg) => {
                                        this.setState({
                                            calendarSelectedDate: e.event.start,
                                        });
                                        this.onOpenEvent(e.event.groupId.toString());
                                    }}
                                    dateClick={(arg: DateClickArg) => {
                                        if (!hasRole(UserRole.OBSERVER)) {
                                            this.setState({
                                                startDate: new Date(arg.dateStr),
                                                time: arg.date.getHours() ? moment(arg.date).format("HH:mm") : "",
                                                openModal: true,
                                                editMode: false,
                                                calendarSelectedDate: new Date(arg.dateStr),
                                            });
                                    }}}
                                    eventTimeFormat={{
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        meridiem: false
                                    }}

                                    eventDidMount={(arg) => {
                                        const d = arg.event.start!;
                                        const dd = [d.getHours(), d.getMinutes()].map((a) => (a < 10 ? '0' + a : a));
                                        let s = dd.join(':') + ": " + arg.event.title;
                                        arg.el.setAttribute("title", s);
                                    }}

                                    initialDate={this.state.currentCalendarDate}
                                    datesSet={(arg) => {
                                        this.setState({currentCalendarDate: arg.view.currentStart})
                                    }}
                                />
                            </MediaQuery>
                            <MediaQuery maxWidth={1224}>
                                <FullCalendar
                                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'month,year'

                                    }}
                                    views={{
                                        year: {
                                            type: 'listYear',
                                            buttonText: 'Повестка год'
                                        },
                                        month: {
                                            type: 'listMonth',
                                            buttonText: 'Повестка месяц'
                                        }
                                    }}
                                    locale={ruLocale}
                                    displayEventTime={true}
                                    nextDayThreshold={'02:00:00'}
                                    initialView="listMonth"
                                    events={this.state.events}
                                    eventClick={(e: EventClickArg) => {
                                        this.setState({
                                            calendarSelectedDate: e.event.start,
                                        });
                                        this.onOpenEvent(e.event.groupId.toString());
                                    }}
                                    dateClick={(arg: DateClickArg) => {
                                        if (!hasRole(UserRole.OBSERVER)) {
                                            this.setState({
                                                startDate: new Date(arg.dateStr),
                                                openModal: true,
                                                editMode: false,
                                                time: arg.date.getHours() ? moment(arg.date).format("HH:mm") : "",
                                                calendarSelectedDate: new Date(arg.dateStr),
                                            });
                                    }}}
                                    eventTimeFormat={{
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        meridiem: false
                                    }}

                                    eventDidMount={(arg) => {
                                        const d = arg.event.start!;
                                        const dd = [d.getHours(), d.getMinutes()].map((a) => (a < 10 ? '0' + a : a));
                                        let s = dd.join(':') + ": " + arg.event.title;
                                        arg.el.setAttribute("title", s);
                                    }}

                                    initialDate={this.state.currentCalendarDate}
                                    datesSet={(arg) => {
                                        this.setState({currentCalendarDate: arg.view.currentStart})
                                    }}
                                />
                            </MediaQuery>

                        </div>
                }
            </div>

        );
    }
}

export default CureProgramTab;
