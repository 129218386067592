import {DoctorTitleCase, getDoctorTitle} from "../utils/common";

export enum FinishTypes {
    COUNTS = 'counts',
    DATE = 'date',
}

export class EventTypes {
    static TAKE_CURE = 'Принять лекарство';
    static VISIT_DOCTOR = 'Посетить врача';
    static GET_TESTED = 'Пройти обследование';
    static INFO = 'Информация для Вас';
    static HOSPITALIZATION = 'Госпитализация';
    static REMINDER = 'Напоминание для врача';
    static DATE_SOCIAL_WORKER = `Дата встречи с ${getDoctorTitle(DoctorTitleCase.om)}`;
    static SUPPORT_MEASURES = 'Меры поддержки';
    static REMINDER_VO = `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`;
}

export enum RepeatTypes {
    NO_REPEATABLE = 'Не повторять',
    DAILY = 'Ежедневно',
    WEEKLY = 'Еженедельно',
    MONTHLY = 'Ежемесячно'
}

export enum DaysOfWeek {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

