import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import {
    Button,
    Container,
    Dropdown, DropdownItemProps,
    DropdownProps,
    Form,
    Header,
    Icon,
    Input,
    Label,
    Modal,
    Segment, Table
} from "semantic-ui-react";
import agent from "../../agent";
import {UserFileType} from "../dashboard";
import { GeneralTitleCase, getGeneralTitle } from "../../utils/common";
import {textLabels} from "../../consts/textLabels";

interface Props {
    onClose: (success: Boolean) => void;
    patientId: string;
}

const PatientFilesUpload = (props: Props) => {
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [userFileTypes, setUserFileTypes] = useState<UserFileType[]>([]);
    const [userFileType, setUserFileType] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function fetchUserFileTypes() {
            let response = (await agent.UserFileTypeProvider.getList());
            setUserFileTypes(response.docs);
        }

        fetchUserFileTypes().then();
    }, []);

    async function onUploadClick() {
        if ((files.length > 0) && userFileType) {
            setUploading(true);
            try {
                let res = true;
                debugger;
                for (const file of files) {
                    const fres = await agent.FileProvider.upload(file, userFileType, props.patientId);
                    if (!fres)
                        res = false;
                }
                if (res)
                    props.onClose(true);
            } finally {
                setUploading(false);
            }
        }
    }

    return (
        <Modal
            onClose={() => props.onClose(false)}
            open={true}
            closeIcon>
            <Modal.Header>Загрузка файла</Modal.Header>
            <Modal.Content>
                <h4>
                    Выберите файл для загрузки
                </h4>
                <input type={"file"} multiple={true} name={"file"} onChange={(event) => {
                    let _files: File[] = [];
                    if (event.target.files) {
                        for (let i = 0; i < event.target.files!.length; i++)
                            _files.push(event.target.files[i]);
                    }
                    setFiles(_files);
                }}/>
                <h4>
                    Выберите тип файла
                </h4>

                <Dropdown
                    selection
                    search={true}
                    clearable={true}
                    value={userFileType}
                    style={{width: "100%"}}
                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        setUserFileType(data.value as string | undefined);
                    }}
                    options={userFileTypes.map((type) => ({
                        key: type._id,
                        text: type.name,
                        value: type._id,
                    } as DropdownItemProps))}
                    noResultsMessage={textLabels.noResultsMessage}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content={"Загрузить"}
                    type={"submit"}
                    disabled={uploading || (files.length == 0) || !userFileType}
                    positive
                    onClick={onUploadClick}
                />
                <Button
                    color={'grey'}
                    disabled={uploading}
                    onClick={() => props.onClose(false)}>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default PatientFilesUpload;