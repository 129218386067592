import {DaysOfWeek} from "../enums/EventEnums";

export const educationForms = [
    {
        name: 'full-time',
        key: 'full_time',
        label: 'Очная',
    },
    {
        name: 'part-time',
        key: 'part_time',
        label: 'Очно-заочная',
    },
    {
        name: 'distance',
        key: 'distance',
        label: 'Заочная',
    }
];

export const fundingSource = [
    {
        name: 'budget',
        key: 'budget',
        label: 'Бюджетная основа',
    },
    {
        name: 'full_reimbursement',
        key: 'full_reimbursement',
        label: 'Полное возмещение затрат',
    }
];

export const getEducationFormsOptions= () => {
    return educationForms.map(item => ({
        key: item.key,
        value: item.name,
        text: item.label
    }))
}

export const getFundingSourceOptions = () => {
    return fundingSource.map(item => ({
        key: item.key,
        value: item.name,
        text: item.label
    }))
}